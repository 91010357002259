import { AuthenticatedResponse, UserLogin, UserProfile } from "../Interfaces";
import { MediaItem, MemberResponse, ReportResponse, emptyUser } from "./UserInterfaces";

const apiurl = process.env.REACT_APP_API_HOST;


const notAuthorizedResponse = {
  isLoggedIn: true,
  errormessage: "Not Authroized",
  step: "ErrorLogin",
  isLoading: false,
  currentUser: emptyUser,
  errors: ["Not Authroized"],
  reportresponse: { mediaItems: undefined, member: undefined, memberList: undefined, donationList: undefined, errors: ["Not Authroized"] },
  credential: { credential: "", email: "" },
}

//spg - removed type CredentialResponse here, used any.. since it wouldnt resolve.. todo: fix this
export function findMember(dataIn: { res: any }) {


  return fetch(apiurl + `/Membership/FindMember?credential=` + dataIn.res.credential, {
    method: "GET",
    credentials: "include",
    headers: {
      pragma: "no-cache",
      "cache-control": "no-cache",
      "content-type": "application/json",
    },
  })
    .then((response) => response.json() as Promise<MemberResponse>)
    .then((memberdata) => {
      return {
        isLoggedIn: true,
        errormessage: "",
        step: "ExistingMember",
        isLoading: false,
        currentUser: memberdata.member,
        credential: dataIn.res,
      }

    })
    .catch((ex) => {
      //TODO: log client errors
      //TODO: SHOW ERROR

      //only used for getting user thumbnail

      return {
        isLoggedIn: true,
        errormessage: ex.message,
        step: "ErrorLogin",
        isLoading: false,
        currentUser: emptyUser,
        credential: dataIn.res,
      }

    });

}

//spg - removed type CredentialResponse here, used any.. since it wouldnt resolve.. todo: fix this
export function handleValidLogin(dataIn: { res: any }) {
  let apipath = `/Membership/AuthorizeReport?credential=` + dataIn.res.credential

  if (dataIn.res.credential) {
    return fetch(apiurl + apipath, {
      method: "GET",
      credentials: "include",
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
        "content-type": "application/json",
      },
    })
      .then((response) => response.json() as Promise<ReportResponse>)
      .then((data) => {

        return {
          isLoggedIn: true,
          errormessage: "",
          step: "Success",
          isLoading: false,
          currentUser: emptyUser,
          errors: [],
          mediaItems: data.mediaItems,
          credential: dataIn.res,
        }

      })
      .catch((ex) => {
        //TODO: log client errors
        //TODO: SHOW ERROR


        return {
          isLoggedIn: true,
          errormessage: ex.message,
          step: "ErrorLogin",
          isLoading: false,
          currentUser: emptyUser,
          errors: [ex.message],
          mediaItems: [],
          credential: dataIn.res,
        }
      });

  } else {

    return {
      isLoggedIn: true,
      errormessage: "Not Authroized",
      step: "ErrorLogin",
      isLoading: false,
      currentUser: emptyUser,
      errors: ["Not Authroized"],
      mediaItems: [],
      credential: dataIn.res,
    }
  }
}