import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, CardBody, CardHeader, CardImg, CardImgOverlay, CardText, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalFooter, ModalHeader, NavItem, Navbar, Row } from "reactstrap";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
    MemberInfoSave, SubmitChessInterestAsync, selectMember, selectOrderCompleteMessage, selectSystemError, selectchildrenArray, selectgradeDDArray, selectValidationError,
    SetValidationError,
    FirstNameChange,
    GradeChange,
    LastNameChange,
    addFormFields,
    gradeDDToggle,
    removeFormFields,
    selectchessStatus
} from "../chess/chessSlice";

import { Form, Field, RadioGroup, Radio } from '@availity/form';
import * as yup from 'yup';
import { setrefreshReCaptcha, setcaptchatoken, selectCaptchaToken, selectrefreshReCaptcha } from '../captcha/captchaSlice';
import { UserProfile } from "../Interfaces";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import LoadingModal from "../LoadingModal";
import { ChessContent } from "./ChessContent";
import { ChessMenu } from "./ChessMenu";

const MemberSendIn = () => {

    const childrenArray = useAppSelector(selectchildrenArray);
    const gradeDDArray = useAppSelector(selectgradeDDArray);
    const ValidationError = useAppSelector(selectValidationError);

    const SystemError = useAppSelector(selectSystemError);
    const captchatoken = useAppSelector(selectCaptchaToken);
    const refreshReCaptcha = useAppSelector(selectrefreshReCaptcha);
    const LoadingStatus = useAppSelector(selectchessStatus);
    const OrderCompleteMessage = useAppSelector(selectOrderCompleteMessage);

    const grades = ["K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    const dispatch = useAppDispatch();

    let childrenValidationScheme: any = {
    }
    let childrenInitialValues: any = {
    }

    for (let i = 0; i < childrenArray.length; i++) {
        childrenValidationScheme["FirstName" + i] = yup.string().required('Please enter a First Name');
        childrenValidationScheme["LastName" + i] = yup.string().required('Please enter a Last Name');

        childrenInitialValues["FirstName" + i] = childrenArray[i].firstname;
        childrenInitialValues["LastName" + i] = childrenArray[i].lastname;
    }
    const myStyle = {
        maxWidth: '1500px'
    }
    return (

        <Container style={myStyle} className="roomfornav">
            <ChessMenu activetab={0}></ChessMenu>
            <Card>

              
                <CardBody>

                    {OrderCompleteMessage !== "" && <Alert>
                        {OrderCompleteMessage}
                    </Alert>

                    }

                    {OrderCompleteMessage === "" &&
                        <>

                            <Form
                                initialValues={childrenInitialValues}
                                validationSchema={
                                    yup.object().shape(childrenValidationScheme)
                                }
                                onSubmit={(values) => {

                                    // let vals: UserProfile = JSON.parse(JSON.stringify(values));
                                    // vals.captchatoken = captchatoken;

                                    let isValid = true;

                                    for (let index = 0; index < childrenArray.length; index++) {
                                        if (childrenArray[index].gradesection === undefined || childrenArray[index].gradesection === "") {
                                            isValid = false;
                                            dispatch(SetValidationError("Please select a grade and section for each student"));
                                        }
                                        if (childrenArray[index].firstname === undefined || childrenArray[index].firstname === "") {
                                            isValid = false;
                                            dispatch(SetValidationError("Please select a first and last name for each student"));
                                        }
                                        if (childrenArray[index].lastname === undefined || childrenArray[index].lastname === "") {
                                            isValid = false;
                                            dispatch(SetValidationError("Please select a first and last name for each student"));
                                        }
                                    }

                                    if (isValid) {
                                        dispatch(SubmitChessInterestAsync({ captchatoken: captchatoken }));
                                    }
                                    // dispatch(setrefreshReCaptcha(!refreshReCaptcha));
                                }}
                            >
                                {(SystemError !== '') && <Alert color="danger">There was a system error.  Please refresh and try again or if the issue persists please send email to <a href="mailto:lehmanjacksonptoboard@gmail.com">lehmanjacksonptoboard@gmail.com</a>. </Alert>}


                                <Label for="childList">Student or students attending LL who would like to join the chess club</Label>
                                {(ValidationError !== '') && <div className='text-danger'>{ValidationError}</div>}

                                {childrenArray.map(
                                    (element, index) => {

                                        let propsordefault = element.gradesection
                                            ? element.gradesection
                                            : "Select Grade";
                                        return (

                                            <div key={"Child" + index}>
                                                <div className="col-md-6 mb-3">
                                                    <Field
                                                        name={"FirstName" + index}
                                                        label="First Name"
                                                        type="text"
                                                        maxLength={50}
                                                        style={{ maxWidth: "370px" }}
                                                        key={"Childfirstname" + index}
                                                        placeholder="First Name"
                                                        value={element.firstname || ""}
                                                        required
                                                        onChange={(e) =>
                                                            dispatch(FirstNameChange({ i: index, firstname: e.target.value }))
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">

                                                    <Field
                                                        name={"LastName" + index}
                                                        label="Last Name"
                                                        type="text"
                                                        maxLength={50}
                                                        style={{ maxWidth: "370px" }}
                                                        key={"Childlastname" + index}
                                                        placeholder="Last Name"
                                                        value={element.lastname || ""}
                                                        required
                                                        onChange={(e) =>
                                                            dispatch(LastNameChange({ i: index, lastname: e.target.value }))
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">

                                                    <Dropdown key={"GradeDD" + index} isOpen={gradeDDArray[index]} toggle={() =>
                                                        dispatch(gradeDDToggle({ i: index }))
                                                    }>
                                                        <DropdownToggle caret>
                                                            {propsordefault}
                                                        </DropdownToggle>
                                                        <DropdownMenu key={"GradeDDM" + index}>
                                                            {grades.map((grade) => (
                                                                <div className="row" key={grade}>
                                                                    <div className="col-sm-4">
                                                                        <DropdownItem
                                                                            value={grade}
                                                                            onClick={() => {
                                                                                dispatch(GradeChange({ i: index, gradesection: grade }))
                                                                            }}
                                                                        >
                                                                            {grade}
                                                                        </DropdownItem>
                                                                    </div>
                                                                    {/* <div className="col-sm-4">
                                                                <DropdownItem onClick={() => {
                                                                    dispatch(GradeChange({ i: index, gradesection: grade + "-2" }))
                                                                }}>
                                                                    {grade + "-2"}
                                                                </DropdownItem>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <DropdownItem onClick={() => {
                                                                    dispatch(GradeChange({ i: index, gradesection: grade + "-3" }))
                                                                }}>
                                                                    {grade + "-3"}
                                                                </DropdownItem>
                                                            </div> */}
                                                                </div>
                                                            ))}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                                {index > 0 ||
                                                    (index <= 0 &&
                                                        childrenArray.length >
                                                        1) ? (
                                                    <button key={"Graderm" + index}
                                                        type="button"
                                                        className="button remove"
                                                        onClick={() =>
                                                            dispatch(removeFormFields({ i: index }))
                                                        }
                                                    >
                                                        Remove Student
                                                    </button>
                                                ) : null}
                                            </div>
                                        );
                                    }
                                )}
                                {(childrenArray.length <
                                    4) && <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <Button onClick={() => dispatch(addFormFields())}>
                                                <span className="fa fa-plus"></span> Add Student
                                            </Button>
                                        </div>
                                    </div>
                                }
                                <div className="d-flex flex-row justify-content-between">

                                    <div className="d-flex flex-col">
                                        <button className="btn btn-inverse btn-primary btn-sm" id="submit">
                                            <strong>Submit</strong>
                                        </button>

                                    </div>

                                </div>

                                <LoadingModal isLoading={LoadingStatus === "loading"}></LoadingModal>
                            </Form>
                        </>
                    }
                    <div className="d-flex flex-col">
                        <div className="d-flex flex-col">
                            <GoogleReCaptcha
                                refreshReCaptcha={refreshReCaptcha}
                                onVerify={useCallback((token) => {
                                    dispatch(setcaptchatoken(token))
                                }, [refreshReCaptcha])}
                            />
                            {/* <div id="recaptchaform"></div> */}
                        </div>
                    </div>


                </CardBody>

            </Card>


        </Container>

    );
};

export default MemberSendIn;



