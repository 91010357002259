import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { SubmitChessInterest } from './chessAPI';
import { Child, DonorCandidate, UserProfile } from '../race/Interfaces';


export interface chessState {

  member: DonorCandidate;
  orderid: void | string;
  systemerror: string;
  error: string;
  OrderCompleteMessage: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: chessState = {
  member:
  {
    Name: "",
    email: "",
    clearances: "",
    childrenArray: [{ firstname: "", lastname: "", gradesection: "" }],
    gradeDDArray: [false],
    phone: "",
    paypalButton: 0,
    validationError: "",
    channel: 0,

  },
  OrderCompleteMessage: "",
  orderid: "",
  systemerror: "",
  error: "",
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const fetchchessAsync = createAsyncThunk(
//   'chess/fetchchess',
//   async (sol: string) => {
//     const response = await fetchchess(sol);
//     // The value we return becomes the `fulfilled` action payload
//     return response;
//   }
// );



export const SubmitChessInterestAsync = createAsyncThunk(
  'user/SubmitIOweYou',
  async (input: {captchatoken: string},  { rejectWithValue, getState }) => { // <-- destructure getState method
    const state: any = getState(); // <-- invoke and access state object
    try {
      const response = await SubmitChessInterest(state.chess.member,input.captchatoken);

      return response;

    } catch (err) {

      return rejectWithValue(err)
    }
  }
);

export const chessSlice = createSlice({
  name: 'chess',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    // Use the PayloadAction type to declare the contents of `action.payload`
    MemberInfoSave: (state, action: PayloadAction<{ Name: string, phone: string, email: string, clearances: string }>) => {
      state.member.Name = action.payload.Name;
      state.member.phone = action.payload.phone;
      state.member.email = action.payload.email;
      state.member.clearances = action.payload.clearances;
    },
    FirstNameChange: (state, action: PayloadAction<{ i: number, firstname: string }>) => {
      state.member.childrenArray[action.payload.i].firstname = action.payload.firstname;
      let temparray = state.member.childrenArray;
      state.member.validationError = resetValidation(temparray);
    },
    gradeDDToggle: (state, action: PayloadAction<{ i: number }>) => {
      state.member.gradeDDArray[action.payload.i] = !state.member.gradeDDArray[action.payload.i];
    },
    LastNameChange: (state, action: PayloadAction<{ i: number, lastname: string }>) => {
      state.member.childrenArray[action.payload.i].lastname = action.payload.lastname;
      let temparray = state.member.childrenArray;
      state.member.validationError = resetValidation(temparray);
    },
    SetValidationError: (state, action: PayloadAction<string>) => {
      state.member.validationError = action.payload;
    },
    SetOrderComplete: (state, action: PayloadAction<string>) => {
      state.OrderCompleteMessage = action.payload;

    },
    GradeChange: (state, action: PayloadAction<{ i: number, gradesection: string }>) => {
      state.member.childrenArray[action.payload.i].gradesection = action.payload.gradesection;
      let temparray = state.member.childrenArray;
      state.member.validationError = resetValidation(temparray);

    },
    addFormFields: (state) => {
      let theChild: Child = { firstname: "", lastname: "", gradesection: "" };
      state.member.childrenArray.push(theChild);
      state.member.gradeDDArray.push(false);
    },
    removeFormFields: (state, action: PayloadAction<{ i: number }>) => {
      let temparray = state.member.childrenArray;
      temparray.splice(action.payload.i, 1);
      let tempddarray = state.member.gradeDDArray;
      tempddarray.splice(action.payload.i, 1);
    },
    initpaypalButton: (state, action: PayloadAction<{ buttonNum: number, channel: number, consumerOnComplete: Function }>) => {

      // if (action.payload.channel !== 102) {
      let ppparent = document.getElementById('ppparent' + action.payload.channel);
      let paybuttonID = "paypal-donate-button-container" + action.payload.channel;
      let btnDiv = '<div id="paypal-donate-button-container' + action.payload.channel + '"></div>';

      var btnRemove = document.getElementById(paybuttonID);
      btnRemove && btnRemove.remove();
      if (ppparent) {
        ppparent.innerHTML = btnDiv;
      }

      if (state.member.childrenArray[0].gradesection === undefined || state.member.childrenArray[0].gradesection === "") {
        //missing some data need to check all valid
      } else {

        let childrenString = "";

        let temparray = state.member.childrenArray;

        for (let index = 0; index < temparray.length; index++) {
          childrenString = childrenString + temparray[index].firstname + " " + temparray[index].lastname + "," + temparray[index].gradesection + "; ";

        }

        let paypalParams: any = {
          // env: 'sandbox',
          // hosted_button_id: 'YKZBUHXZH8LXN',
          env: 'production',
          item_name: 'chess for education opt-out for ' + state.member.Name + ' parent of ' + childrenString,
          custom: JSON.stringify({ phone: state.member.phone, email: state.member.email, childrenArray: state.member.childrenArray, clearances: state.member.clearances }),
          // business: 'YOUR_EMAIL_OR_PAYERID',
          image: {
            src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
            title: 'PayPal - The safer, easier way to pay online!',
            alt: 'PayPal button'

          },
          onComplete: action.payload.consumerOnComplete,
        }

        switch (action.payload.channel) {
          case 0:
            paypalParams["amount"] = (temparray.length > 1) ? 75 : 50;
            paypalParams["hosted_button_id"] = (temparray.length > 1) ? 'N67FUU3DKDMTA' : '9R5Z5T44WQF8G'

            break;
          case 1:
            paypalParams["item_name"] = 'chess for education donation for ' + state.member.Name + ' sponsor of ' + childrenString;
            paypalParams["hosted_button_id"] = 'V2T3FHWYA67GQ'

            break;
          case 2:
            paypalParams["item_name"] = 'Membership dues for  ' + state.member.Name + ' parent/guardian of ' + childrenString;
            paypalParams["hosted_button_id"] = 'Z63EMYTMUHSTS'
            paypalParams["image"] = {
              // src: 'https://www.paypalobjects.com/en_US/i/btn/btn_paynow_LG.gif',

              src: 'https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/44_Yellow_PayPal_Pill_Button.png',
              title: 'PayPal - The safer, easier way to pay online!',
              alt: 'PayPal button'

            }

            break;
          //sandbox channels
          case 100:
            paypalParams["amount"] = (temparray.length > 1) ? 75 : 50;
            paypalParams["hosted_button_id"] = (temparray.length > 1) ? 'YKZBUHXZH8LXN' : 'GZU3MARC8JML2'
            paypalParams["env"] = 'sandbox'

            break;
          case 101:
            paypalParams["item_name"] = 'chess for education donation for ' + state.member.Name + ' sponsor of ' + childrenString;
            paypalParams["hosted_button_id"] = '34SV9SZMDAF3W'
            paypalParams["env"] = 'sandbox'

            break;
          case 102:
            paypalParams["item_name"] = 'Membership dues for  ' + state.member.Name + ' parent/guardian of ' + childrenString;
            paypalParams["hosted_button_id"] = 'KR8KKUMCMTUJS'
            paypalParams["env"] = 'sandbox'
            paypalParams["image"] = {
              // src: 'https://www.paypalobjects.com/en_US/i/btn/btn_paynow_LG.gif',
              src: 'https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/44_Yellow_PayPal_Pill_Button.png',
              title: 'PayPal - The safer, easier way to pay online!',
              alt: 'PayPal button'

            }

            break;

          default:
            break;
        }

        // @ts-ignore
        PayPal.Donation.Button(paypalParams).render('#' + paybuttonID);

        state.member.paypalButton = action.payload.buttonNum;

        // }
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // .addCase(fetchchessAsync.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(fetchchessAsync.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   state.results = action.payload;
      // })
      // .addCase(fetchchessAsync.rejected, (state) => {
      //   state.status = 'failed';
      //   state.systemerror ="Unknown Error";
      // })   
        
      .addCase(SubmitChessInterestAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(SubmitChessInterestAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.orderid = action.payload;
        state.systemerror = "";
        state.OrderCompleteMessage = "Thank you for submitting your interest! We look forward to seeing your student(s) at the chesss club.  Please check class dojo for updates.";
      })
      .addCase(SubmitChessInterestAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.systemerror = action.error.message ? action.error.message : "Unknown Error";
        
      });
  },
});

export const { SetOrderComplete,
  MemberInfoSave,
  FirstNameChange,
  LastNameChange,
  SetValidationError,
  GradeChange,
  removeFormFields,
  addFormFields,
  initpaypalButton,
  gradeDDToggle } = chessSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.chess.value)`
export const selectchildrenArray = (state: RootState) => state.chess.member.childrenArray;
export const selectgradeDDArray = (state: RootState) => state.chess.member.gradeDDArray;
export const selectPaypalButton = (state: RootState) => state.chess.member.paypalButton;
export const selectMember = (state: RootState) => state.chess.member;
export const selectValidationError = (state: RootState) => state.chess.member.validationError;
export const selectOrderCompleteMessage = (state: RootState) => state.chess.OrderCompleteMessage;
export const selectSystemError = (state: RootState) => state.chess.systemerror;
export const selectchessStatus = (state: RootState) => state.chess.status;


export default chessSlice.reducer;
function resetValidation(temparray: Child[]) {

  let newValidationError = "";
  for (let index = 0; index < temparray.length; index++) {
    if (temparray[index].gradesection === undefined || temparray[index].gradesection === "") {
      newValidationError = "Please select a grade for each student";
    }
    if (temparray[index].firstname === undefined || temparray[index].firstname === "") {
      newValidationError = "Please select a first and last name for each student";
    }
    if (temparray[index].lastname === undefined || temparray[index].lastname === "") {
      newValidationError = "Please select a first and last name for each student";
    }
  }
  return newValidationError;
}

