import React from "react";
import { Link } from "react-router-dom";
import { NavItem, Navbar } from "reactstrap";
import { EffectsCard } from "../../components/EffectsCard";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import modalSlice, { toggleCommitteeForms, toggleSchoolLinks } from "../Modals/modalSlice";
import SchoolLinks from "../Modals/SchoolLinks";
import CommitteeForms from "../Modals/CommitteeForms";

const Members = () => {


    const dispatch = useAppDispatch();

    return (
        <section id="members" className="anchor">
            <div className="container">
                <div className="container mb-5"></div>
{/* 
                <h2 className="heading text-center">PTO Officers 2023 - 2024</h2>

                <div className="row">
                    <div className="col-sm-4 text-center mt-4">
                        <div className="card">
                            <h3 className="card-header">President:</h3>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Anna Fedor</strong></li>
                                <li className="list-group-item"><a href="mailto:amfedor@gmail.com">amfedor@gmail.com</a></li>
                                <li className="list-group-item">570-574-7830</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-4 text-center mt-4">
                        <div className="card">
                            <h3 className="card-header">Vice President:</h3>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Steve Greblunas</strong></li>
                                <li className="list-group-item">
                                    <a href="mailto:greblunas@gmail.com">greblunas@gmail.com</a>
                                </li>
                                <li className="list-group-item">570-406-2896</li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-sm-4 text-center mt-4">
                        <div className="card">
                            <h3 className="card-header">2nd Vice President:</h3>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Richelle Steele</strong></li>
                                <li className="list-group-item"><a href="mailto:rjsteele522@frontier.com">rjsteele522@frontier.com</a></li>
                                <li className="list-group-item">570-690-1482</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-2 text-center mt-4">

                    </div>
                    <div className="col-sm-4 text-center mt-4">
                        <div className="card">
                            <h3 className="card-header">Treasurer:</h3>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Melissa Gregor</strong></li>
                                <li className="list-group-item"><a href="mailto:Misbg2@aol.com">Misbg2@aol.com</a></li>
                                <li className="list-group-item">570-262-8280</li>
                            </ul>
                        </div>
                    </div>


                    <div className="col-sm-4 text-center mt-4">
                        <div className="card">
                            <h3 className="card-header">Secretary:</h3>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Becki Brandenburg</strong></li>
                                <li className="list-group-item"><a href="mailto:beckibranden8@yahoo.com">beckibranden8@yahoo.com</a></li>
                                <li className="list-group-item">570-706-0195</li>
                            </ul>
                        </div>
                    </div>

                </div> */}


                <div className="row">

                    <div className="col-12" >
                        <h2 className="heading text-center mt-5">Membership</h2>
                        <div id="overlay"></div>
                        <div className="container">
                            <div className="jumbotron">
                                <h2>Please take part in our annual PTO membership drive!</h2>
                                <h3>We invite parents, guardians, grandparents, and teachers to be active in the PTO programs at Lehman-Jackson Elementary. </h3><h3>The cost for each member is $5.00 and will go directly to activities for the students at Lehman-Jackson. </h3><h3>Please complete the form at the button below and send in the $5.00/person payment with your student and label it as "membership drive" in a sealed envelope. Checks can be made payable to "LJE-PTO".</h3>

                                <h3><b>Please note that in order to be eligible to chaperone for the LJE field trips for the 2023-2024 school year, you need to join the PTO and submit payment by 11/1/2024.</b></h3>
                                <h3><b>Also note:  no cash greater than $30 accepted.</b></h3>

                            </div>
                            <div className="containereffects row d-flex flex-row">
                                <div className="col-sm-2 col-xs-12">
                                    <EffectsCard
                                        Title={"JOIN!"}
                                        Icon="fa-handshake"
                                        Color="pink"
                                        Animation="rocket-flight"
                                    // Link="/Do/App1"
                                    >
                                        {/* <a className="btn btn-primary btn-lg" href="https://forms.gle/ARtcpcReQ1sLbMow6" target="_blank" role="button">JOIN</a> */}
                                        <a className="btn btn-primary btn-lg" href="/membership" role="button">JOIN</a>
                                    </EffectsCard>
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <EffectsCard
                                        Title="Clearance Information"
                                        Icon="fa-school-flag"
                                        Color="pink"
                                        Animation="rocket-flight"
                                    // Link="/Do/App1"
                                    >
                                        <button className="mx-5 mb-5 btn btn-primary btn-lg" onClick={() => {
                                            dispatch(toggleSchoolLinks());
                                        }}>Clearance Info</button>
                                        <SchoolLinks />
                                    </EffectsCard>
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <EffectsCard
                                        Title="Committee Forms"
                                        Icon="fa-pen"
                                        Color="pink"
                                        Animation="rocket-flight"
                                    // Link="/Do/App1"
                                    >
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                dispatch(toggleCommitteeForms());
                                            }}
                                        >
                                            Committee Forms
                                        </button>
                                        <CommitteeForms />
                                    </EffectsCard>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </section >
    );
};

export default Members;



