import { Card, CardHeader, CardText, Nav, NavItem, Navbar, TabContent, TabPane } from 'reactstrap';
import { useAppDispatch } from '../../app/hooks';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import TabTitle from "./TabTitle"
import { setActiveTab } from '../tabs/tabSlice';

export function ChessMenu(props: { activetab: number; }) {

  const dispatch = useAppDispatch();

  return (
    <>
      <Card>

        <CardHeader>
          <img
            alt="knight on horse"
            src="https://cdn.sanity.io/images/vp13tjdy/production/a961ca1d8a0646e815090e4c667ef15e4d8a28cf-654x972.png"

            className="img-fluid knightimg"
          />
          <CardText tag="h2">
            Chess Club has been restarted at LJE!

          </CardText>
          <CardText>The club is open to all LL district students and will meet to learn and play chess. Specific dates/times and locations will be sent out from the LJE-PTO on class dojo or your schools facebook page.</CardText>
          <CardText>
            Please submit the interest form below if you have not already. We welcome LL student walk-ins any week! Interest form is helpful to get an estimate of attendees in order to have enough materials and chess supplies ready.
            Online materials can be found here as well for llsd students to use at home.
            if you have any questions, please contact us at <a href="mailto:lehmanjacksonptoboard@gmail.com">lehmanjacksonptoboard@gmail.com</a>
          </CardText>
        </CardHeader>
      </Card>
     
      <ul className="nav nav-tabs dark">
        <li className="nav-item">
          <NavLink className={props.activetab == 0 ? 'nav-link active' : 'nav-link '} aria-current="page" to="/chess/interest">Sign up for Chess Club!</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={props.activetab == 1 ? 'nav-link active' : 'nav-link '} aria-current="page" to="/chess/materials">Materials and Activities</NavLink>
        </li>

      </ul>

    </>
  );
}

