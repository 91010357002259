import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader, NavItem, Navbar } from "reactstrap";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectSchoolLinksOpen, toggleSchoolLinks } from "./modalSlice";

const SchoolLinks = () => {

    
    const schoolLinksModalIsOpen = useAppSelector(selectSchoolLinksOpen);

    const dispatch = useAppDispatch();

    return (
        <Modal
            isOpen={schoolLinksModalIsOpen}
            toggle={() => {dispatch(toggleSchoolLinks())}}
            backdrop={"static"}
            keyboard={false}
            className="searchmodal"
        >
            <ModalHeader toggle={() => {dispatch(toggleSchoolLinks())}}>
                School Information
            </ModalHeader>
            <ModalBody>
                <div className="card">
                    <ul className="list-group list-group-flush">

                        <li className="list-group-item"><a target="_blank" href="https://www.llsd.org/accnt_214735/site_214736/Documents/School-Volunteer-Information.pdf">School Volunteer Information</a></li>

                        <li className="list-group-item"><a target="_blank" href="https://www.llsd.org/Human_Resources">Volunteer Clearances Information</a></li>

                        <li className="list-group-item"><a target="_blank" href="https://www.llsd.org/Governing_Board">Board of Education</a></li>

                        <li className="list-group-item"><a target="_blank" href="https://www.llsd.org/Food_Services">Breakfast/Lunch Information</a></li>

                        <li className="list-group-item"><a target="_blank" href="https://www.llsd.org/Lehman_Jackson_Elementary_School">Lehman-Jackson Elementary School</a></li>

                        <li className="list-group-item"><a target="_blank" href="https://parentsis.csiu-technology.org/Login.aspx?ReturnUrl=%2f">Parent Portal</a></li>

                    </ul>
                </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
                                                                                    
    );
};

export default SchoolLinks;



