import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle, Col, Container, NavItem, Navbar, Row } from "reactstrap";

const EmergencyServices = () => {

    useEffect(() => {
        let ppparent = document.getElementById('ppparent-emergency-services');
        let paybuttonID = "paypal-donate-button-container-emergency-services";
        let btnDiv = '<div id="paypal-donate-button-container-emergency-services"></div>';

        var btnRemove = document.getElementById(paybuttonID);
        btnRemove && btnRemove.remove();
        if (ppparent) {
            ppparent.innerHTML = btnDiv;
        }

        let paypalParams: any = {
            // env: 'sandbox',
            // hosted_button_id: 'YKZBUHXZH8LXN',
            env: 'production',
            item_name: 'Emergency Services 2024-2025 Donation',
            image: {
                src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
                title: 'PayPal - The safer, easier way to pay online!',
                alt: 'PayPal button'

            },
            // onComplete: action.payload.consumerOnComplete,
        }

        paypalParams["hosted_button_id"] = 'AU7RTWADNGDGU';

        // @ts-ignore
        PayPal.Donation.Button(paypalParams).render('#' + paybuttonID);

        // }

    }, []);

    return (
        <section className="showcase bgemergencyservices roomfornav">
            <div id="overlay"></div>
            <div
                className="d-flex flex-column align-items-center "
            >
                <div className="donateheader">
                    <Container>
                        <Row>
                            <Col><Card>

                                <CardBody>
                                    <CardTitle className="text-center"><strong>Emergency Services Day is held at the beginning of the school year each year.</strong></CardTitle>

                                    <CardText>

                                        The PTO is asking for monetary donations to provide refreshments and lunch for the first responders volunteering their time that day!
                                        If you are interested in donating, please use the PayPal button below or send your donation in an envelope labeled “Emergency Services Day - PTO”.
                                        Checks can be made out to “LJE-PTO”. Please send in your donations before october.
                                    </CardText>

                                </CardBody>
                                <CardFooter id="ppparent-emergency-services">

                                    <div id="paypal-donate-button-container-emergency-services"></div>
                                </CardFooter>
                            </Card></Col>
                        </Row>


                    </Container>
                </div>
            </div>
        </section>
    );
}
export default EmergencyServices;



