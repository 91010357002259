import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface BtnDroprightState {
  value: boolean[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: BtnDroprightState = {
  value: [],
  status: 'idle',
};


export const btnDroprightSlice = createSlice({
  name: 'btnDropright',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {   
    // Use the PayloadAction type to declare the contents of `action.payload`
    setBtnDropright: (state, action: PayloadAction<{index: number, expanded: boolean}>) => {
      let currentVals: boolean[] = state.value;
      currentVals[action.payload.index] = action.payload.expanded;
      state.value = currentVals;
    },
  },
  
});

export const { setBtnDropright } = btnDroprightSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.btnDropright.value)`
export const selectBtnDropright = (state: RootState) => state.btnDropright.value;

export default btnDroprightSlice.reducer;
