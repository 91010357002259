import { Child, DonorCandidate } from "../race/Interfaces";

export const emptyUser = {
  name: '',
  email: '',
  membershipStatus: '',
  credential: '',
  thumbnail: '',
  authorizedPages: [],
  childrenArray: [],
};

export interface Member {
  name: string;
  email: string;
  childrenArray: Child[];
  membershipStatus: string;
  credential: string;
  thumbnail: string;
  authorizedPages: string[];
}

export interface Donation {
  donor: DonorCandidate;
  amount: number;
  drive: string;
}

export interface MemberResponse {
  member: Member;
  errors: string[];

}
export interface MediaItem {
  filename: string;
  baseUrl: string,
  mimeType: string,
}
export interface ReportResponse {
  mediaItems: MediaItem[];

}
export interface UserState {
  currentUser: Member
  isLoading: boolean;
  isLoggedIn: boolean;
  step: string;
  systemerror: string;
  errors: string[];
  status: 'idle' | 'loading' | 'failed';
  mediaItems: MediaItem[];
}

