import * as React from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";


interface LoadingProps {
  isLoading: boolean
}

const LoadingModal: React.FC<LoadingProps> = ({ isLoading }) => {
    if (isLoading) {
      return (
        <div key="loadingmodals">
         
            <Modal className="modal-lg " isOpen={isLoading}>
              <ModalHeader>Please Wait</ModalHeader>
              <ModalBody>
                <Spinner size="sm" color="secondary" />
                <div>Please wait. This may take a few moments...</div>
              </ModalBody>
            </Modal>

          
        </div>
      );
    } else {
      return <></>;
    }
  }

  export default LoadingModal;