import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { authorizePrivateContent } from './privateContentAPI';
import { emptyUser, MediaItem, Member } from '../user/UserInterfaces';

const baseMedia = [
  {
    filename:"",
    baseUrl: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3ESign%20In%20To%20View%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
    mimeType: 'Please signin to view content',
  },
  // {
  //   baseUrl: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  //   mimeType: 'Slide 2',
  // },
  // {
  //   baseUrl: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  //   mimeType: 'Slide 3',
  // }
];
const baseCredential: any =
{

}
export interface privateContentState {
  privateContent: [MediaItem[], MediaItem[]];
  errors: string[];
  animating: boolean;
  activeIndex: number;
  pageNumber: number;
  preload: boolean;
  isLoggedIn: boolean;
  step: string;
  currentUser: Member;
  credential: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: privateContentState = {

  privateContent: [baseMedia, baseMedia],
  animating: false,
  activeIndex: 0,
  pageNumber: 0,
  preload: false,
  isLoggedIn: false,
  step: "Login",
  currentUser: emptyUser,
  errors: [],
  credential: baseCredential,
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchprivateContentAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const authorizePrivateContentAsync = createAsyncThunk(
  'server/authorizePrivateContent',
  async (dataIn: { res: any, contentID: string, pagenumber: number }, { rejectWithValue, getState }) => {

    const state: any = getState(); // <-- invoke and access state object
    try {

      const [response1] = await Promise.all([
        authorizePrivateContent(dataIn.res, dataIn.contentID, dataIn.pagenumber)
      ]);

      return response1;
    } catch (err) {

      return rejectWithValue(err)
    }
  }
);

export const privateContentSlice = createSlice({
  name: 'privateContent',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    UpdateUserState: (state, action: PayloadAction<{
      // systemerror: string | undefined,
      // errors: string[] | undefined,
      // animating: boolean | undefined,
      // activeIndex: number | undefined,
      // pageNumber: number | undefined,
      // preload: boolean | undefined,
      isLoggedIn: boolean | undefined,
      // step: string | undefined,
      // credential: any | undefined,
    }>) => {
      //let x = y || def_val;
      // action.payload.errors = (typeof action.payload.errors === 'undefined') ? state.errors : action.payload.errors;
      // action.payload.animating = (typeof action.payload.animating === 'undefined') ? state.animating : action.payload.animating;
      // action.payload.activeIndex = (typeof action.payload.activeIndex === 'undefined') ? state.activeIndex : action.payload.activeIndex;
      // action.payload.pageNumber = (typeof action.payload.pageNumber === 'undefined') ? state.pageNumber : action.payload.pageNumber;
      // action.payload.preload = (typeof action.payload.preload === 'undefined') ? state.preload : action.payload.preload;
       action.payload.isLoggedIn = (typeof action.payload.isLoggedIn === 'undefined') ? state.isLoggedIn : action.payload.isLoggedIn;
      // action.payload.step = (typeof action.payload.step === 'undefined') ? state.step : action.payload.step;
      // action.payload.credential = (typeof action.payload.credential === 'undefined') ? state.credential : action.payload.credential;

      // if(action.payload.isLoggedIn)
      // {
      //   state.privateContent = [baseMedia, baseMedia]
      // }

    },

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(authorizePrivateContentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(authorizePrivateContentAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        let newPrivateContent = state.privateContent;
        switch (action.payload.contentID.toLowerCase()) {
          case "chessmaterials":
            newPrivateContent[1] = action.payload.mediaItems;
            state.privateContent = newPrivateContent;
            break;

          case "yearbook":
            newPrivateContent[0] = action.payload.mediaItems;
            state.privateContent = newPrivateContent;
            break;

          default:
            break;
        }


        state.isLoggedIn = action.payload.isLoggedIn;
        state.step = action.payload.step;
        state.currentUser = action.payload.currentUser;
        state.errors = action.payload.errors;
      })
      .addCase(authorizePrivateContentAsync.rejected, (state) => {
        state.status = 'failed';
        state.errors = ["We could not authorize you to view this content.  Please try again with a valid school district account."];
      });
  },
});

export const {
  UpdateUserState
} = privateContentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.privateContent.value)`
export const selectprivateContent = (state: RootState) => state.privateContent.privateContent;
export const selectpageNumber = (state: RootState) => state.privateContent.pageNumber;
export const selectpreload = (state: RootState) => state.privateContent.preload;
export const selecterrors = (state: RootState) => state.privateContent.errors;
export const selectisLoggedIn = (state: RootState) => state.privateContent.isLoggedIn;
export const selectprivateContentStatus = (state: RootState) => state.privateContent.status;


export default privateContentSlice.reducer;
