import { MemberResponse, emptyUser } from "../user/UserInterfaces";
import { privateContent } from "./privateContentInterface";

export function authorizePrivateContent(res: any, contentID: string, pagenumber: number) {
  let apipath = `/Membership/AuthorizePrivateContent?pagenumber=` + pagenumber + `&contentID=` + contentID + `&credential=` + res.credential

  if (res.credential) {

    return fetch(process.env.REACT_APP_API_HOST + apipath, {
      method: "GET",
      credentials: "include",
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
        "content-type": "application/json",
      },
    })
      .then((response) => response.json() as Promise<privateContent>)
      .then((data) => {
        //TODO: SHOW SUCCESS
        if (data.errors) {
          return {
            step: "ErrorLogin",
            isLoading: false,
            errors: data.errors,
            mediaItems: data.mediaItems,
            contentID: contentID,

          };
        } else {
          return {
            step: "NewMember",
            isLoading: false,
            errormessage: "",
            isLoggedIn: true,
            mediaItems: data.mediaItems,
            contentID: contentID,
          };
        }
      })
      .catch((error) => {
        console.table(error);
        return error.then(Promise.reject.bind(Promise));
      });

  } else {
    return Promise.reject.bind(Promise);
  }
}