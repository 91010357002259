import { useCallback, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  fetchCalendarAsync,
  selectCalendar,
} from './calendarSlice';


import Moment from 'react-moment';
import 'moment-timezone';


export function Calendar() {
  const calendar = useAppSelector(selectCalendar);
  const dispatch = useAppDispatch();

  const initFetch = useCallback(() => {
    if (calendar && calendar.length > 0) { } else {
      dispatch(fetchCalendarAsync());

    }
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])
  return (
    <section id="calendar" className="anchor mx-3">
      <div className="container mb-5"></div>
      <h2 className="heading text-center">Important Dates</h2>
      <div className="ml-1 d-flex flex-wrap align-items-end flex-row">
        {
          calendar && calendar.map((calendaritem, index) =>

            <div key={'mykey' + index} className="col-md-6 col-lg-4 row row-striped">
              <div className="col-3 col-md-2 text-right">
                <h1 className="display-4">
                  <span className="badge  text-bg-secondary">
                    <Moment tz="America/New_York" format="DD">
                      {calendaritem.when}
                    </Moment>

                  </span></h1>
                <h2>
                  <Moment tz="America/New_York" format="MMM">
                    {calendaritem.when}
                  </Moment>
                </h2>
              </div>
              <div className="col-9 col-md-10">
                <h3 className="text-uppercase ml-3"><strong>{calendaritem.summary}</strong></h3>
                <ul className="list-inline">
                  <li className="list-inline-item"><i className="fa fa-calendar-o" aria-hidden="true"></i>
                    <Moment format="dddd, h:mm a">
                      {calendaritem.when}
                    </Moment>
                  </li>
                  <li className="list-inline-item"><i className="fa fa-location-arrow" aria-hidden="true"></i> {calendaritem.location}</li>
                </ul>
                <p>{calendaritem.description}</p>
              </div>
            </div>
          )}

      </div>

    </section>
  );
}
