import React from "react";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Col, Container, NavItem, Navbar, Row } from "reactstrap";
import { EffectsCard } from "../../components/EffectsCard";
import EmergencyServices from "../landingpages/EmergencyServices";

const Fundraisers = () => {
    return (
        <div className="donateheader anchor" id="fundraisers" >
            <Container>
                {/* <Row className="mt-5">
                    <Col>
                        <Card>

                            <CardBody>
                                <h1>
                                    <img className="img-fluid" src="https://cdn.sanity.io/images/vp13tjdy/production/d00661d1c764d4001d1d258d6aa88b1ca049394d-5000x1667.jpg" />
                                </h1>

                                <h3>The PTO will be hosting an online spirit wear sale beginning today (Wed. November 1st). The sale will run through Nov. 11th! There are some great items available!!</h3>

                                <h3>Visit the store using the link below: <a className="btn btn-primary btn-lg" href="https://stores.fhprint.com/lake_lehman_pto/shop/home">Click Here</a></h3>



                            </CardBody>

                        </Card>
                    </Col>
                </Row> */}

                <Row className="mt-5">
                    <Col>
                        <Card>

                            <CardBody>
                                <h1><img c-btfe_primaryheaderunified_btfe_primaryheaderunified="" className="img-fluid" src="https://www.boxtops4education.com/resource/1658323173000/BTFE_BoxTopLogo" /> Box Tops</h1>
                                <h2>Join the PTO in earning cash for our school by using the Box Tops app. </h2>
                                <hr></hr>
                                <h4>

                                    It’s an easy way to make a difference. All you have to do is buy Box Tops participating products (like Cheerios!) and scan your grocery receipt. BoxTops are worth $.10 each and they add up fast! Twice a year, our school receives a check to help pay for whatever we need - equipment, supplies or experiences the kids love!

                                    Use this referral code <Badge>FTAZ2SA6</Badge> when you sign up and you’ll get 20 Bonus Box Tops when you scan your first receipt! </h4>
                                <h3>Download the Box Tops for Education app here: <a className="btn btn-primary btn-lg" href="https://btfe.smart.link/c2jjdifkw?referral_code=FTAZ2SA6">Click Here</a></h3>




                            </CardBody>

                        </Card>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <Card>

                            <CardBody>
                                <h1><img alt="AdvantEdge Card" src="https://cdn.spinwheel.io/images/tcc/t4s_banner.png" className="img-fluid" />Tools For Schools</h1>
                                <h2>Parents and Supporters,

                                    Help Our School Earn Points For Free Classroom Equipment by
                                    Registering your AdvantEdge Card for Price Chopper’s</h2>
                                <hr></hr>
                                <h3>School Code:17296</h3>
                                <h4><a target="_blank" className="btn btn-primary btn-lg" href="https://rewards.pricechopper.com/tools-for-schools/signup">Click Here</a></h4>




                            </CardBody>

                        </Card>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <Card>
                            <CardBody>
                                <h1><img c-btfe_primaryheaderunified_btfe_primaryheaderunified="" className="img-fluid" src="https://cdn.sanity.io/images/vp13tjdy/production/7216a5f75c87cb135d879507dcd4f5256194dd81-258x195.png" /> Race For Education</h1>
                                <h2>Join the PTO for our largest fundraiser of the year! </h2>
                                <hr></hr>
                                <div className="col-sm-2 col-xs-12">

                                    <a className="btn btn-primary btn-lg" href="/race" role="button">Get Started!</a>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <Card>
                            <CardBody>
                                <EmergencyServices />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


            </Container>
        </div>
    );
};

export default Fundraisers;



