import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface NavState {
  isOpen: boolean;
}

const initialState: NavState = {
  isOpen: false,
};


export const navSlice = createSlice({
  name: 'nav',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {   
    // Use the PayloadAction type to declare the contents of `action.payload`
    setNavIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
  
});

export const { setNavIsOpen } = navSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.nav.value)`
export const selectNavIsOpen = (state: RootState) => state.nav.isOpen;

export default navSlice.reducer;
