import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface TabState {
  value: number;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: TabState = {
  value: 1,
  status: 'idle',
};


export const tabSlice = createSlice({
  name: 'tab',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {   
    // Use the PayloadAction type to declare the contents of `action.payload`
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
  
});

export const { setActiveTab } = tabSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.tab.value)`
export const selectActiveTab = (state: RootState) => state.tab.value;

export default tabSlice.reducer;
