import React from "react";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Col, Container, NavItem, Navbar, Row } from "reactstrap";
import { EffectsCard } from "../../components/EffectsCard";
import EmergencyServices from "../landingpages/EmergencyServices";
import ChessInterest from "../chess/ChessInterest";
import MusicSurvey from "../music/MusicSurvey";

const Fundraisers = () => {
    return (
        <div className="donateheader anchor" id="fundraisers" >
            <Container>

                {/* <Row className="mt-5">
                    <Col>
                        <Card>

                            <CardBody>
                                <h1><img
                                    alt="knight on horse"
                                    src="https://cdn.sanity.io/images/vp13tjdy/production/2237ff6433540d0b5f0421804dd7ce0679f811b8-819x1024.jpg"

                                    className="img-fluid flyerimg"
                                /></h1>
                                <h2>Nashville Knights 5th and 6th grade dance</h2>
                                <hr></hr>
                                <h4><a target="_blank" className="btn btn-primary btn-lg" href="https://lje-pto.com/music">Click Here</a></h4>

                            </CardBody>

                        </Card>

                    </Col>
                </Row> */}
                <Row className="mt-5">
                    <Col>

                        <CardBody>
                            <h1> <img
                                alt="knight on horse"
                                src="https://cdn.sanity.io/images/vp13tjdy/production/a961ca1d8a0646e815090e4c667ef15e4d8a28cf-654x972.png"

                                className="img-fluid knightimg"
                            /></h1>
                            <h2>Chess Club</h2>
                            <hr></hr>
                            <h4><a target="_blank" className="btn btn-primary btn-lg" href="https://lje-pto.com/chess">Click Here</a></h4>

                        </CardBody>

                    </Col>
                </Row>


            </Container>
        </div>
    );
};

export default Fundraisers;



