import { CalendarItem } from "./CalendarInterface";

// A mock function to mimic making an async request for data
export function fetchCalendar() {
  return fetch(
    process.env.REACT_APP_API_HOST +
    `/ptocalendar`,
    {
      method: "GET",
      // credentials: "include",
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
        // 'Authorization': 'Bearer ' + localStorage.getItem("accessToken"),
        'Content-Type': 'application/x-www-form-urlencoded'
      },

    }
  )
    .then((response) => {
      if (!response.ok) {

        return [];

      } else {


        return response.json() as Promise<CalendarItem[]>;

      }
    })
    .catch((error) => {
      console.table(error);
      return error.then(Promise.reject.bind(Promise));
    });
}
