import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchRace, SubmitIOweYou, UpdateVenmoOrder } from './raceAPI';
import { Child, DonorCandidate, RaceFundraisingResults, UserProfile } from './Interfaces';





export interface raceState {

  donor: DonorCandidate;
  results: RaceFundraisingResults;
  orderid: void | string;
  systemerror: string;
  error: string;
  OrderCompleteMessage: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: raceState = {
  donor:
  {
    Name: "",
    email: "",
    clearances: "",
    childrenArray: [{ firstname: "", lastname: "", gradesection: "" }],
    gradeDDArray: [false],
    phone: "",
    paypalButton: 0,
    validationError: "",
    channel: 0,

  },
  OrderCompleteMessage: "",
  orderid: "",
  systemerror: "",
  error: "",
  status: 'idle',
  results: { "K_total": 0.0, "First_total": 0.0, "Second_total": 0.0, "Third_total": 0.0, "Fourth_total": 0.0, "Fifth_total": 0.0, "Sixth_total": 0.0, "unknown_total": 0.0, "School_total": 0.0, "School_goal": 15000.0 } as RaceFundraisingResults
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchRaceAsync = createAsyncThunk(
  'race/fetchRace',
  async (sol: string) => {
    const response = await fetchRace(sol);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// export const CreateVenmoOrderAsync = createAsyncThunk(
//   'user/CreateVenmoOrder',
//   async (dataIn: DonorCandidate, captchatoken: string, { rejectWithValue }) => {
//     try {
//       const response = await CreateVenmoOrder(dataIn, captchatoken);

//       // The value we return becomes the `fulfilled` action payload
//       return response;
//     } catch (err) {

//       return rejectWithValue(err)
//     }
//   }
// );

export const UpdateVenmoOrderAsync = createAsyncThunk(
  'user/UpdateVenmoOrder',
  async (data: { DonationName: string, amount: number, sol: string, orderid: string }, { rejectWithValue, getState }) => { // <-- destructure getState method
    const state: any = getState(); // <-- invoke and access state object
    try {

      const response = await UpdateVenmoOrder(data.DonationName, data.amount, data.sol, data.orderid, state.race.donor);
      // The value we return becomes the `fulfilled` action payload
      return response;
    } catch (err) {

      return rejectWithValue(err)
    }
  }
);

export const SubmitIOweYouAsync = createAsyncThunk(
  'user/SubmitIOweYou',
  async (input: {captchatoken: string},  { rejectWithValue, getState }) => { // <-- destructure getState method
    const state: any = getState(); // <-- invoke and access state object
    try {
      const response = await SubmitIOweYou(state.race.donor,input.captchatoken);

      return response;

    } catch (err) {

      return rejectWithValue(err)
    }
  }
);

export const raceSlice = createSlice({
  name: 'race',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    // Use the PayloadAction type to declare the contents of `action.payload`
    DonorInfoSave: (state, action: PayloadAction<{ Name: string, phone: string, email: string, clearances: string }>) => {
      state.donor.Name = action.payload.Name;
      state.donor.phone = action.payload.phone;
      state.donor.email = action.payload.email;
      state.donor.clearances = action.payload.clearances;
    },
    FirstNameChange: (state, action: PayloadAction<{ i: number, firstname: string }>) => {
      state.donor.childrenArray[action.payload.i].firstname = action.payload.firstname;
      let temparray = state.donor.childrenArray;
      state.donor.validationError = resetValidation(temparray);
    },
    gradeDDToggle: (state, action: PayloadAction<{ i: number }>) => {
      state.donor.gradeDDArray[action.payload.i] = !state.donor.gradeDDArray[action.payload.i];
    },
    LastNameChange: (state, action: PayloadAction<{ i: number, lastname: string }>) => {
      state.donor.childrenArray[action.payload.i].lastname = action.payload.lastname;
      let temparray = state.donor.childrenArray;
      state.donor.validationError = resetValidation(temparray);
    },
    SetValidationError: (state, action: PayloadAction<string>) => {
      state.donor.validationError = action.payload;
    },
    SetOrderComplete: (state, action: PayloadAction<string>) => {
      state.OrderCompleteMessage = action.payload;

    },
    GradeChange: (state, action: PayloadAction<{ i: number, gradesection: string }>) => {
      state.donor.childrenArray[action.payload.i].gradesection = action.payload.gradesection;
      let temparray = state.donor.childrenArray;
      state.donor.validationError = resetValidation(temparray);

    },
    addFormFields: (state) => {
      let theChild: Child = { firstname: "", lastname: "", gradesection: "" };
      state.donor.childrenArray.push(theChild);
      state.donor.gradeDDArray.push(false);
    },
    removeFormFields: (state, action: PayloadAction<{ i: number }>) => {
      let temparray = state.donor.childrenArray;
      temparray.splice(action.payload.i, 1);
      let tempddarray = state.donor.gradeDDArray;
      tempddarray.splice(action.payload.i, 1);
    },
    initpaypalButton: (state, action: PayloadAction<{ buttonNum: number, channel: number, consumerOnComplete: Function }>) => {

      // if (action.payload.channel !== 102) {
      let ppparent = document.getElementById('ppparent' + action.payload.channel);
      let paybuttonID = "paypal-donate-button-container" + action.payload.channel;
      let btnDiv = '<div id="paypal-donate-button-container' + action.payload.channel + '"></div>';

      var btnRemove = document.getElementById(paybuttonID);
      btnRemove && btnRemove.remove();
      if (ppparent) {
        ppparent.innerHTML = btnDiv;
      }

      if (state.donor.childrenArray[0].gradesection === undefined || state.donor.childrenArray[0].gradesection === "") {
        //missing some data need to check all valid
      } else {

        let childrenString = "";

        let temparray = state.donor.childrenArray;

        for (let index = 0; index < temparray.length; index++) {
          childrenString = childrenString + temparray[index].firstname + " " + temparray[index].lastname + "," + temparray[index].gradesection + "; ";

        }

        let paypalParams: any = {
          // env: 'sandbox',
          // hosted_button_id: 'YKZBUHXZH8LXN',
          env: 'production',
          item_name: 'Race for education opt-out for ' + state.donor.Name + ' parent of ' + childrenString,
          custom: JSON.stringify({ phone: state.donor.phone, email: state.donor.email, childrenArray: state.donor.childrenArray, clearances: state.donor.clearances }),
          // business: 'YOUR_EMAIL_OR_PAYERID',
          image: {
            src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
            title: 'PayPal - The safer, easier way to pay online!',
            alt: 'PayPal button'

          },
          onComplete: action.payload.consumerOnComplete,
        }

        switch (action.payload.channel) {
          case 0:
            paypalParams["amount"] = (temparray.length > 1) ? 75 : 50;
            paypalParams["hosted_button_id"] = (temparray.length > 1) ? 'N67FUU3DKDMTA' : '9R5Z5T44WQF8G'

            break;
          case 1:
            paypalParams["item_name"] = 'Race for education donation for ' + state.donor.Name + ' sponsor of ' + childrenString;
            paypalParams["hosted_button_id"] = 'V2T3FHWYA67GQ'

            break;
          case 2:
            paypalParams["item_name"] = 'Membership dues for  ' + state.donor.Name + ' parent/guardian of ' + childrenString;
            paypalParams["hosted_button_id"] = 'Z63EMYTMUHSTS'
            paypalParams["image"] = {
              // src: 'https://www.paypalobjects.com/en_US/i/btn/btn_paynow_LG.gif',

              src: 'https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/44_Yellow_PayPal_Pill_Button.png',
              title: 'PayPal - The safer, easier way to pay online!',
              alt: 'PayPal button'

            }

            break;
          //sandbox channels
          case 100:
            paypalParams["amount"] = (temparray.length > 1) ? 75 : 50;
            paypalParams["hosted_button_id"] = (temparray.length > 1) ? 'YKZBUHXZH8LXN' : 'GZU3MARC8JML2'
            paypalParams["env"] = 'sandbox'

            break;
          case 101:
            paypalParams["item_name"] = 'Race for education donation for ' + state.donor.Name + ' sponsor of ' + childrenString;
            paypalParams["hosted_button_id"] = '34SV9SZMDAF3W'
            paypalParams["env"] = 'sandbox'

            break;
          case 102:
            paypalParams["item_name"] = 'Membership dues for  ' + state.donor.Name + ' parent/guardian of ' + childrenString;
            paypalParams["hosted_button_id"] = 'KR8KKUMCMTUJS'
            paypalParams["env"] = 'sandbox'
            paypalParams["image"] = {
              // src: 'https://www.paypalobjects.com/en_US/i/btn/btn_paynow_LG.gif',
              src: 'https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/44_Yellow_PayPal_Pill_Button.png',
              title: 'PayPal - The safer, easier way to pay online!',
              alt: 'PayPal button'

            }

            break;

          default:
            break;
        }

        // @ts-ignore
        PayPal.Donation.Button(paypalParams).render('#' + paybuttonID);

        state.donor.paypalButton = action.payload.buttonNum;

        // }
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchRaceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRaceAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.results = action.payload;
      })
      .addCase(fetchRaceAsync.rejected, (state) => {
        state.status = 'failed';
        state.systemerror ="Unknown Error";
      })
      .addCase(UpdateVenmoOrderAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(UpdateVenmoOrderAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.orderid = action.payload;
        state.OrderCompleteMessage = "Thank you for your donation! Your order ID is: " + action.payload;
      })
      .addCase(UpdateVenmoOrderAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.systemerror = action.error.message ? action.error.message : "Unknown Error";
      })
      .addCase(SubmitIOweYouAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(SubmitIOweYouAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.orderid = action.payload;
        state.systemerror = "";
        state.OrderCompleteMessage = "Thank you for your completing your registration! Please send in $5 per member with your contact info and students info to school clearly label it as \"membership drive\" in a sealed envelope. Checks can be made payable to \"LJE-PTO\"";
      })
      .addCase(SubmitIOweYouAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.systemerror = action.error.message ? action.error.message : "Unknown Error";
        
      });
  },
});

export const { SetOrderComplete,
  DonorInfoSave,
  FirstNameChange,
  LastNameChange,
  SetValidationError,
  GradeChange,
  removeFormFields,
  addFormFields,
  initpaypalButton,
  gradeDDToggle } = raceSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.race.value)`
export const selectchildrenArray = (state: RootState) => state.race.donor.childrenArray;
export const selectgradeDDArray = (state: RootState) => state.race.donor.gradeDDArray;
export const selectPaypalButton = (state: RootState) => state.race.donor.paypalButton;
export const selectDonor = (state: RootState) => state.race.donor;
export const selectValidationError = (state: RootState) => state.race.donor.validationError;
export const selectresults = (state: RootState) => state.race.results;
export const selectOrderCompleteMessage = (state: RootState) => state.race.OrderCompleteMessage;
export const selectSystemError = (state: RootState) => state.race.systemerror;
export const selectRaceStatus = (state: RootState) => state.race.status;


export default raceSlice.reducer;
function resetValidation(temparray: Child[]) {

  let newValidationError = "";
  for (let index = 0; index < temparray.length; index++) {
    if (temparray[index].gradesection === undefined || temparray[index].gradesection === "") {
      newValidationError = "Please select a grade for each student";
    }
    if (temparray[index].firstname === undefined || temparray[index].firstname === "") {
      newValidationError = "Please select a first and last name for each student";
    }
    if (temparray[index].lastname === undefined || temparray[index].lastname === "") {
      newValidationError = "Please select a first and last name for each student";
    }
  }
  return newValidationError;
}

