import { DonorCandidate, UserProfile } from "../race/Interfaces";


export function SubmitChessInterest(currentdonor: DonorCandidate, captchatoken: string) {

  // if (captchatoken) {
  let postData = { donor: currentdonor  }

  return fetch(
    process.env.REACT_APP_API_HOST +
    `/Membership/SubmitChessInterest` + (captchatoken ? `?captchatoken=${captchatoken}` : ''),
    {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(postData),
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
        "content-type": "application/json",
      },
    }
  )
    .then((response: Response) => {
      if (!response.ok) {
        if (response.statusText && response.statusText !== '') {
          throw response.statusText;
        }
        else {
          throw new Error("System Error");
        }
      } else {
        return response.json() as Promise<string>;
      }
    })
    .catch((error) => {
      console.table(error);
      throw error;
    });

  // } else {
  //   throw new Error("Invalid Captcha");
  // }
}


