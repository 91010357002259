import React from "react";
import { Link } from "react-router-dom";
import { NavItem, Navbar } from "reactstrap";

const Footer = () => {
  return (

    <div className="navbox">
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm box-shadow "
        dark
      >
        <ul className="navbar-nav flex-grow align-items-center">
          <NavItem className="nav-item">
            {!window.location.href.includes("privacy") && (
              <Link to="/privacy" onClick={() => {
                window.scrollTo(0, 0);
              }}>
                <button id="privacy" className="btn btn-outline-secondary btn-xs bg-light" ><i className="fa fa-child m-1" aria-hidden="true"></i>Privacy</button>
              </Link>)}
          </NavItem>
        </ul>
      </Navbar>
    </div>
  );
};

export default Footer;

