import * as React from "react";
import { Alert, Button, Card, CardBody, Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem, Container, Modal, ModalBody, ModalHeader } from "reactstrap"
import { GoogleSignIn } from "../user/GoogleSignIn";
import { MediaItem } from "../user/UserInterfaces";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { UpdateUserState, authorizePrivateContentAsync, selecterrors, selectisLoggedIn, selectpageNumber, selectpreload, selectprivateContent, selectprivateContentStatus } from "../privateContent/privateContentSlice";
import { CredentialResponse } from "../privateContent/privateContentInterface";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { ChessMenu } from "./ChessMenu";
const apiurl = process.env.REACT_APP_API_HOST;

let message = "Sign In to view the book:";

const systemError = "We could not authorize you.  This page is for Lehman student, faculty or specific authorized users.";
const submitSystemError = "There was a problem.  please try again.";



const baseMedia = [
  {
    baseUrl: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3ESign%20In%20To%20View%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
    mimeType: 'Please signin to view materials',
  },
  // {
  //   baseUrl: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  //   mimeType: 'Slide 2',
  // },
  // {
  //   baseUrl: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  //   mimeType: 'Slide 3',
  // }
];


const bookStyle = {
  margin: 0
} as React.CSSProperties;

var orientation = (window.screen.orientation || {}).type;

if (orientation === "landscape-primary") {
  //console.log("That looks good.");
} else if (orientation === "landscape-secondary") {
  //console.log("Mmmh... the screen is upside down!");
} else if (orientation === "portrait-secondary" || orientation === "portrait-primary") {
  //console.log("Mmmh... you should rotate your device to landscape");
} else if (orientation === undefined) {
  //console.log("The orientation API isn't supported in this browser :(");
}

let bookWidth = 340;
let bookHeight = 440;

try {

  if (window.innerWidth > 500) {
    if (window.innerWidth >= 768) {
      bookWidth = 680;
      bookHeight = 440;
      if (window.innerWidth > 800) {
        bookWidth = 850;
        bookHeight = 550;
      }
      if (window.innerWidth > 1024) {
        bookWidth = 1020;
        bookHeight = 660;
      }
    } else {
      if (window.innerWidth >= 602) {
        bookWidth = 566.666667;
        bookHeight = 366.66667;
      }
    }
  } else {

  }
} catch (e) { }



const options = {
  // width: 3060, 1530x2
  // height: 1980,
  width: bookWidth,//window.innerWidth > 500 ? 1020 : 340,
  height: bookHeight,//window.innerWidth > 500 ? 660 : 440,
  autoCenter: true,
  display: window.innerWidth > 500 ? "double" : "single",
  acceleration: true,
  elevation: 50,
  // gradients: !$.isTouch,
  when: {
    turned: function (e: any, page: any) {
      //console.log("Turned");
    }
  }
};

export function ChessContent() {

 

  const errors = useAppSelector(selecterrors);
  const isLoggedIn = useAppSelector(selectisLoggedIn);
  const pageNumber = useAppSelector(selectpageNumber);
  const preload = useAppSelector(selectpreload);
  const privateContent = useAppSelector(selectprivateContent);
  const status = useAppSelector(selectprivateContentStatus);
  const dispatch = useAppDispatch();

  let theContentItems = privateContent[1]

  const slides = theContentItems && theContentItems.map((item: MediaItem) => {
    return (
      <CarouselItem
        //onExiting={this.onExiting}
        //onExited={this.onExited}
        key={item.baseUrl}
      >
        <img className="img-fluid" src={item.baseUrl + "=w2550-h3300"} alt={item.mimeType} />
        {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
      </CarouselItem>
    );
  });
  let pages: string[] = [];
  theContentItems && theContentItems.map((item: MediaItem, index: number) => {
    if (index < 300) {
      //      pages.push(item.baseUrl + "=w1530-h1980");
      pages.push(item.baseUrl + "=w2550-h3300");
      //pages.push(item.baseUrl + "=w" + (window.innerWidth > 500 ? "510" : "340") + "-h" + (window.innerWidth > 500 ? "660" : "440"));
    }
  });

  let images = theContentItems && pages.map((page, index) => {
    return {
      original: page, thumbnail: page, originalClass: "img-fluid", thumbnailClass: "img-fluid"

    }
  });

  const myStyle = {
    maxWidth: '1500px'
  }
  return (

    <Container style={myStyle} className="roomfornav">

      <ChessMenu activetab={1}></ChessMenu>
      {/* {orientation}
      {window.innerWidth}
      {window.innerHeight}
      {bookWidth}
      {bookHeight} */}
      <Modal isOpen={status === 'loading'}>
        <ModalHeader>
          Please Wait...
        </ModalHeader>
        <ModalBody>
          <div className="spinner-grow text-warning" role="status">
            {/* <span className="visually-hidden">Loading...</span> */}
          </div>
          this might take a few moments
        </ModalBody>
      </Modal>

      {window.location.href.toLowerCase().includes("print") && (
        <Alert>You are previewing the PRINT version of the yearbook to be printed.  Only authorized users will be able to gain access.</Alert>)}


      {!window.location.href.toLowerCase().includes("print") && !isLoggedIn && (
        // <Alert>You are previewing the ONLINE version of the yearbook intended to be an expanded edition.  Only authorized users will be able to gain access.</Alert>
        <Alert>Please Login with your school account.  If you were individually given access with an alternate account please login with that account.</Alert>
      )}


      <GoogleSignIn
        Message={message}
        Errors={errors}
        isLoggedIn={isLoggedIn}
        onSuccess={(res: CredentialResponse) => {
          console.log(res);
          dispatch(UpdateUserState({
            isLoggedIn: true,
            // errors: [],
            // step: "Checking",
            // credential: res,
            // systemerror: undefined,
            // animating: undefined,
            // activeIndex: undefined,
            // pageNumber: undefined,
            // preload: undefined
          }));
          dispatch(authorizePrivateContentAsync({ res: res, contentID: "ChessMaterials", pagenumber: pageNumber }));
        }}
        onFailure={() => {
          console.log("Failure");
          dispatch(UpdateUserState({
            isLoggedIn: false,
            // errors: [systemError],
            // step: "ErrorLogin",
            // credential: undefined,
            // systemerror: undefined,
            // animating: undefined,
            // activeIndex: undefined,
            // pageNumber: undefined,
            // preload: undefined
          }));

        }}
        parentLogout={() => {

          dispatch(UpdateUserState({
            isLoggedIn: false,
            // errors: undefined,
            // step: "Login",
            // credential: undefined,
            // systemerror: undefined,
            // animating: undefined,
            // activeIndex: undefined,
            // pageNumber: undefined,
            // preload: undefined
          }));
          window.location.reload();

        }}

        UserImage={""}
      />
      {pages.length > 1 &&
        <ImageGallery items={images} />
      }
    </Container>

  )
}


