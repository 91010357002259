import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchCalendar } from './calendarAPI';
import { CalendarItem } from './CalendarInterface';


export interface CalendarState {
  calendar: CalendarItem[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CalendarState = {

  calendar: [],
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchCalendarAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCalendarAsync = createAsyncThunk(
  'server/fetchCalendar',
  async () => {
    const response = await fetchCalendar();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalendarAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCalendarAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.calendar = action.payload;
      })
      .addCase(fetchCalendarAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {  } = calendarSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.calendar.value)`
export const selectCalendar = (state: RootState) => state.calendar.calendar;


export default calendarSlice.reducer;
