import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import captchaReducer from '../features/captcha/captchaSlice';
import userReducer from '../features/user/userSlice';
import tabReducer from '../features/tabs/tabSlice';
import navReducer from '../features/navmenu/navSlice';
import raceReducer from '../features/race/raceSlice';
import calendarReducer from '../features/calendar/calendarSlice';
import minutesReducer from '../features/minutes/minutesSlice';
import chessReducer from '../features/chess/chessSlice';
import btnDroprightReducer from '../features/btnDropright/btnDroprightSlice';
import modalReducer from '../features/Modals/modalSlice';
import privateContentReducer from '../features/privateContent/privateContentSlice';

export const store = configureStore({
  reducer: {
    captcha: captchaReducer,
    user: userReducer,
    tab: tabReducer,
    btnDropright: btnDroprightReducer,
    modal: modalReducer,
    nav: navReducer,
    race: raceReducer,
    calendar: calendarReducer,
    minutes: minutesReducer,
    chess: chessReducer,
    privateContent : privateContentReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
