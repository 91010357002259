import React from "react";
import { Link } from "react-router-dom";
import { NavItem, Navbar } from "reactstrap";

const Committees = () => {
    return (
        <section id="committees" className="anchor">
        <div className="container mb-5"></div>
        <div className="container bg-light">
          <h2 className="heading text-center">Committees</h2>         
          <a className="btn btn-primary" href="/committees">Committee Interest Form</a>         
        </div>
      </section>
    );
};

export default Committees;



