import React from "react";
import { Link } from "react-router-dom";
import { NavItem, Navbar } from "reactstrap";

const Showcase = () => {
    return (
        <section id="showcase" className="d-flex flex-column align-items-center anchor">
            <div id="overlay"></div>
            <div id="header" className="container m-auto text-primary text-center intro-text py-2">

                <h5 className="display-2 mb-0 text-dark d-none d-md-block">LJE PTO</h5>
                <h5 className="fs-5 mb-md-5 text-dark display-5 display-md-3">Proudly supporting the students of Lehman-Jackson Elementary School</h5>
                <div className="display">
                    <div className="flexbox default">
                        <div className="display-box">
                            {/* <button id="event-cta" className="hero-icon yellow btn bomb-boom " data-toggle="modal" data-target="#eventctaModal"><i className="main far fa-calendar-alt"></i></button> */}
                            {/* <button id="gallery-cta" className="hero-icon yellow btn bomb-boom " data-toggle="modal" data-target="#galleryModal"><i className="main far fa-calendar-alt"></i></button> */}
                        </div>
                    </div>
                </div>
                {/* <button id="donate" className="btn btn-outline-danger btn-xs bg-light" data-toggle="modal" data-target="#bookingModal"><i className="fa fa-heart" aria-hidden="true"></i>Teacher Appreciation</button> */}

                {/* <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/open?id=1AthJ7MKXXttGZIKu-PgIG43M_aE8PJ4S&authuser=lehmanjacksonptoboard%40gmail.com&usp=drive_fs">
            <button id="donate" className="btn btn-outline-danger btn-xs bg-light" ><i className="fa fa-school" aria-hidden="true"></i>  Tools FOR Schools</button>
          </a> */}
                {/* <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1B0izYzS15oGgEwpgMVAK3UH4f0w953Ao/view?usp=sharing">
            <button id="donate" className="btn btn-outline-danger btn-xs bg-light" ><i className="fa fa-school" aria-hidden="true"></i> PTO OPEN HOUSE</button>
          </a> */}
                {/* <Link to="/NextMeeting" >
            <button id="meeting" className="btn btn-outline-danger btn-lg" ><i className="fa fa-calendar" aria-hidden="true"></i>March 2023 Meeting</button>
            </Link> */}
            </div>
        </section>
    );
};

export default Showcase;



