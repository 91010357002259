import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { findMember, handleValidLogin } from './userAPI';
import { UserLogin, UserProfile } from '../Interfaces';
import { emptyUser, ReportResponse, UserState } from './UserInterfaces';




const initialState: UserState = {
  currentUser: emptyUser,
  isLoading: false,
  isLoggedIn: false,
  step: '0',
  systemerror: '',
  errors: [],
  status: 'idle',
  mediaItems: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const fetchUserAsync = createAsyncThunk(
//   'user/fetchUser',
//   async () => {
//     const response = await fetchUser();
//     // The value we return becomes the `fulfilled` action payload
//     return response;
//   }
// );

export const handleValidLoginAsync = createAsyncThunk(
  'user/handleValidLogin',
  async (dataIn: { res: any }, { rejectWithValue }) => {
    try {
      // const response = await handleValidLogin(dataIn);

      const [response1, response2] = await Promise.all([
        handleValidLogin(dataIn),
        findMember(dataIn),
      ]);

      return await {
        isLoggedIn: true,
        errormessage: response1.errors[0],
        step: "Success",
        isLoading: false,
        currentUser: response2.currentUser,
        errors: response1.errors,
        mediaItems: response1.mediaItems,
        credential: dataIn.res,
      };


    } catch (err) {

      return rejectWithValue(err)
    }
  }
);


export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {    // Use the PayloadAction type to declare the contents of `action.payload`
    handleLoginError: (state, action: PayloadAction<{ errormessage: string }>) => {
      state.errors = [action.payload.errormessage];
      state.isLoggedIn = false;
      state.step = "ErrorLogin";
      state.isLoading = false;
      state.currentUser = emptyUser;
    },   // Use the PayloadAction type to declare the contents of `action.payload`
    handleLogout: (state, action: PayloadAction) => {
      state.errors = [];
      state.isLoggedIn = false;
      state.step = "Login";
      state.isLoading = false;
      state.currentUser = emptyUser;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // .addCase(fetchUserAsync.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(fetchUserAsync.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   state.currentUser = action.payload;
      // })
      // .addCase(fetchUserAsync.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.error.message ? action.error.message : "Unknown Error";
      // })
      .addCase(handleValidLoginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(handleValidLoginAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload) {
          state.mediaItems = action.payload.mediaItems;
          state.currentUser.credential = action.payload.credential;
          state.isLoggedIn = true;
          state.step = action.payload.step;
          state.isLoading = false;
          state.currentUser = action.payload.currentUser;
        }
      })

      .addCase(handleValidLoginAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = [action.error.message ? action.error.message : "Unknown Error"];
      })
  },
});

export const { handleLoginError, handleLogout } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserData = (state: RootState) => state.user;

export default userSlice.reducer;
