import React, { PureComponent } from "react";
import { useEffect, useState } from "react"
import { Button, Card, CardBody, CardHeader } from "reactstrap"


const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

interface GoogleSigninProps {
  onSuccess?: Function;
  onFailure?: Function;
  parentLogout?: Function;
  Errors: String[];
  Message: String;
  isLoggedIn: boolean;
  UserImage: string;
}

export const GoogleSignIn: React.FC<GoogleSigninProps> = ({
  onSuccess,
  onFailure,
  parentLogout,
  Errors,
  Message,
  isLoggedIn,
  UserImage
}) => {

  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
  const [user, setUser] = useState("")


  const logOut = () => {
    (async () => {
      // if (googleAuth) {
      //   await googleAuth.signOut();
      // }
      parentLogout && parentLogout();
      initializeGsi();
    })();
  };
  const initializeGsi = () => {
    // Typescript will complain about window.google
    // Add types to your `react-app-env.d.ts` or //@ts-ignore it.

    //@ts-ignore
    if (!window.google || gsiScriptLoaded) return

    setGsiScriptLoaded(true)

    //@ts-ignore
    window.google.accounts.id.initialize({
      client_id: googleClientId,
      auto_select: true,
      callback: handleGoogleSignIn,
    })
    //@ts-ignore
    window.google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large" }  // customization attributes
    );
    //@ts-ignore
    user == "" && window.google.accounts.id.prompt(); // also display the One Tap dialog


  }

  //spg - removed type CredentialResponse here, used any.. since it wouldnt resolve.. todo: fix this
  const handleGoogleSignIn = (res: any) => {
    if (!res.clientId || !res.credential) return

    // Implement your login mutations and logic here.
    // Set cookies, call your backend, etc. 
    //initializeGsi();
    onSuccess && onSuccess(res);

    setUser(res.credential)

  }

  useEffect(() => {
    // @ts-ignore
    if (user !== "" || gsiScriptLoaded) return


    const script = document.createElement("script")
    script.src = "https://accounts.google.com/gsi/client"
    script.onload = initializeGsi
    script.async = true
    script.id = "google-client-script"
    document.querySelector("body")?.appendChild(script)

    return () => {
      // Cleanup function that runs when component unmounts
      //@ts-ignore
      window.google?.accounts.id.cancel()
      document.getElementById("google-client-script")?.remove()
    }

  }, [handleGoogleSignIn, initializeGsi, user])


  return (
    <Card
      className="my-2"
      color="dark"
      outline
    >
      <CardHeader className="pt-1 pb-1">
        {!(Errors === undefined || Errors.length === 0 || Errors[0] === undefined || Errors[0] === "") &&
          Errors.map((error, index) => {
            return <div className="alert alert-danger" role="alert">{error}</div>
          })
        }

      </CardHeader>
      <CardBody className="p-1 p-1">
        <div className="d-flex justify-content-around">
          {Message}
          {isLoggedIn && <img src={UserImage} className="img-fluid" style={{ height: "41px" }} />}
          {isLoggedIn && <button className='btn btn-info btn-xs' onClick={logOut}><i className={"fa fa-sign-out"}></i>Sign Out</button>}
        </div>
        <div className="d-flex justify-content-around" style={{ visibility: isLoggedIn ? "hidden" : "visible", maxHeight: isLoggedIn ? "1px" : "50px" }}>

          <div id="buttonDiv" ></div>
        </div>

      </CardBody>
    </Card>

  );

}