import React, { useRef, useState } from 'react';
import { gsap } from "gsap";
import { Navbar, NavbarToggler, Collapse, NavItem, Button } from 'reactstrap';
import { selectNavIsOpen, setNavIsOpen } from './navSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { HashLink as Link } from 'react-router-hash-link';


export function NavMenu() {

  const NavIsOpen = useAppSelector(selectNavIsOpen);
  const dispatch = useAppDispatch();

  return (
    <>
      <nav className="navbox topnav">

        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm box-shadow "
          // fixed="top"
          dark
        >
          <>
            <ul className="navbar-nav flex-grow align-items-center">
              <NavItem className="nav-item">
                <a className=" nav-link text-light" onClick={() => { dispatch(setNavIsOpen(false)) }} href={process.env.PUBLIC_URL} data-di-id="di-id-74781012-163657f9">

                  <h3 className="mb-0">LJE PTO</h3>
                </a>
              </NavItem>
            </ul>
            {!window.location.href.includes("race") && (
              <Link to="/race" onClick={() => {
                window.scrollTo(0, 0);
              }}>
                <Button id="donate" color="danger" className="btn-xs" >
                  <i className="fa fa-heart" aria-hidden="true"></i>Race For Education!
                </Button>
              </Link>)}

            {/* {!window.location.href.includes("NextMeeting") && (
            <Link to="/NextMeeting" >
            <button id="meeting" className="btn btn-outline-primary btn-xs" ><i className="fa fa-calendar" aria-hidden="true"></i>March 2022 Meeting</button>
            </Link>)} */}
            <NavbarToggler onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="mr-2" />
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={NavIsOpen}
              fixed="top"
              navbar
            >
              <ul className="navbar-nav flex-grow align-items-center">

                <NavItem className="nav-item">

                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/#calendar">
                    Calendar
                  </NavLink>
                </NavItem>

                <NavItem className="nav-item">
                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/#mission">
                    Mission
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/#members">
                    Members
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/committees">
                    Committees
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/#minutes">
                    Minutes/Info
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/#fundraisers">
                    Fundraisers
                  </NavLink>
                </NavItem>
                {/* <NavItem className="nav-item">
                  <NavLink target='_blank' onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="https://bookfairs.scholastic.com/bf/lehmanjacksonelemschool">
                    Book Fair
                  </NavLink>
                </NavItem> */}
                {/* <NavItem className="nav-item">
                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/music">
                    Music
                  </NavLink>
                </NavItem> */}
                <NavItem className="nav-item">
                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/events">
                    Events
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/#contact">
                    Contact Us
                  </NavLink>
                </NavItem>
                {/* <NavItem className="nav-item">
                  <NavLink onClick={() => { dispatch(setNavIsOpen(!NavIsOpen)) }} className="text-primary" to="/yearbook">
                    Yearbook
                  </NavLink>
                </NavItem> */}

              </ul>


            </Collapse>
          </>
        </Navbar >
      </nav >
    </>
  );
}

