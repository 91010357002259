import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchminutes } from './minutesAPI';
import { minutesItem } from './minutesInterface';


export interface minutesState {
  minutes: minutesItem[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: minutesState = {

  minutes: [],
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchminutesAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchminutesAsync = createAsyncThunk(
  'server/fetchminutes',
  async () => {
    const response = await fetchminutes();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const minutesSlice = createSlice({
  name: 'minutes',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchminutesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchminutesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.minutes = action.payload;
      })
      .addCase(fetchminutesAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {  } = minutesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.minutes.value)`
export const selectminutes = (state: RootState) => state.minutes.minutes;


export default minutesSlice.reducer;
