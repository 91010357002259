import React from "react";
import { Link } from "react-router-dom";
import { NavItem, Navbar } from "reactstrap";

const Contact = () => {
    return (
        <section id="contact" className="p-3 text-white anchor">
            <div className="container">
                <div className="row">

                    <div className="col-md-6 col-md-offset-3 text-center wrap_title">
                        <h2>Join our Facebook group or Contact Us on our facebook page!</h2>
                        <a className="btn btn-primary btn-lg" href="https://www.facebook.com/groups/ljepto/">LJE PTO GROUP</a>
                    </div>

                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLehman-Jackson-Elementary-PTO-474383499248226%2F&tabs=messages&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                        width="340" height="500" style={{ border: "none", overflow: "hidden" }}
                        scrolling="no" frameBorder="0" allowTransparency={true}
                        allow="encrypted-media" />

                </div>
            </div>
        </section>
    );
};

export default Contact;



