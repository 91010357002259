import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardTitle, Col, Container, NavItem, Navbar, Row } from "reactstrap";

const Tos = () => {
    return (
        <section className="showcase bg1 roomfornav">
            <div id="overlay"></div>
            <div
                className="d-flex flex-column align-items-center "
            >
                <div className="donateheader">
                    <Container>
                        <Row>
                            <Col><Card>

                                <CardBody>
                                    <CardTitle className="text-center"><strong>
                                        Terms of Service</strong></CardTitle>
                                    <CardSubtitle>We do not have any terms associated with this app.</CardSubtitle>
                                    <hr></hr>


                                </CardBody>
                            </Card></Col>
                        </Row>


                    </Container>
                </div>
            </div>
        </section>
    );
};

export default Tos;



