import * as React from "react";

import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import sanityClient from "../../client.js";
import { Post, Category, slug } from "../music/Interfaces";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { GoogleSignIn } from "../user/GoogleSignIn";
// import { CircleNav } from "../../components/CircleNav";
import { CommitteeButton } from "../../components/CommitteeButton";

const systemError = "We could not log you in.  please try again or submit our google form.";
const submitSystemError = "There was a problem.  please try again or submit our google form.";

const emptyUser = { name: "", email: "", currentInterests: [], membershipStatus: "", credential: "", thumbnail: "" };
interface Signup {
  committee: string;
  role: string;
}

interface Member {
  name: string;
  email: string;
  currentInterests: Signup[];
  membershipStatus: string;
  credential: string;
  thumbnail: string;
}

interface MemberResponse {
  member: Member;
  errors: string[];

}

const apiurl = process.env.REACT_APP_API_HOST;
export default class CommitteeSignup extends React.PureComponent<
  {},
  {
    isOpen: boolean;
    slug: string;
    postData: Post[];
    step: string;
    isLoading: boolean;
    errormessage: string | undefined;
    submiterrormessage: string | undefined;
    successmessage: string | undefined;
    selectionStarted: boolean;
    currentUser: Member;
    submitStarted: boolean;
    currentSignup: Signup;
    errors: string[];
    isLoggedIn: boolean;
    credential: string;
  }
> {
  public state = {
    isOpen: false,
    slug: "",
    postData: [],
    step: "Login",
    isLoading: false,
    errormessage: undefined,
    submiterrormessage: undefined,
    successmessage: undefined,
    selectionStarted: false,
    submitStarted: false,
    currentUser: emptyUser,
    currentSignup: { committee: "", role: "" },
    errors: [],
    isLoggedIn: false,
    credential: "",
  };


  public CancelSelection(): void {
    this.setState({
      isOpen: false,
      slug: "",
      isLoading: false,
      errormessage: undefined,
      selectionStarted: false,
      submitStarted: false,
    });

  }


  public Authorize = (res: any) => {

    if (res.credential) {

      fetch(apiurl + `/Membership/FindMember?credential=` + res.credential, {
        method: "GET",
        credentials: "include",
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
          "content-type": "application/json",
        },
      })
        .then((response) => response.json() as Promise<MemberResponse>)
        .then((data) => {
          //TODO: SHOW SUCCESS
          if (data.errors) {
            this.setState({
              step: "ErrorLogin",
              currentUser: data.member,
              isLoading: false,
              errors: data.errors

            });
          } else {
            if (data.member.membershipStatus === "Existing") {
              this.setState({
                step: "ExistingMember",
                currentUser: data.member,
                isLoading: false,
                isLoggedIn: true,

              });
            } else {
              if (data.member.membershipStatus === "New") {
                this.setState({
                  step: "NewMember",
                  currentUser: data.member,
                  isLoading: false,
                  errormessage: "",
                  isLoggedIn: true,
                });
              } else {
                this.setState({
                  step: "ErrorLogin",
                  isLoading: false,
                  currentUser: emptyUser,
                  errormessage: systemError,
                });
              }
            }
          }
        })
        .catch((ex) => {
          //TODO: log client errors
          //TODO: SHOW ERROR

          this.setState({
            step: "ErrorLogin",
            isLoading: false,
            currentUser: emptyUser,
            errormessage: systemError,
          });
        });

      this.setState({
        isLoading: true,
        currentUser: emptyUser,
      });
    } else {
      this.setState({
        step: "ErrorLogin",
        isLoading: false,
        currentUser: emptyUser,
        errormessage: systemError,
      });
    }
  };


  public handleSubmit = () => {

    if (this.state.currentUser.email !== "") {
      console.log(JSON.stringify(this.state.currentUser));

      fetch(apiurl + `/Membership/SubmitRole?email=` + this.state.currentUser.email + `&name=` + this.state.currentUser.name + `&credential=` + this.state.currentUser.credential, {
        method: "POST",
        body: JSON.stringify(this.state.currentSignup),
        credentials: "include",
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
          "content-type": "application/json",
        },
      })
        .then((response) => response.json() as Promise<MemberResponse>)
        .then((data) => {
          //TODO: SHOW SUCCESS
          if (data.errors) {
            this.setState({
              isLoading: false,
              errors: data.errors,
              selectionStarted: false,
              submitStarted: false,

            });
          }
          this.setState({
            step: "SubmitSuccess",
            currentUser: data.member,
            isLoading: false,
            selectionStarted: false,
            submitStarted: false,
            successmessage: "You have successfully submitted your interest as " + this.state.currentSignup.role + " for " + this.state.currentSignup.committee,

          });

          window.setTimeout(() => {
            this.setState({
              successmessage: undefined,

            });

          }, 2000);

        })
        .catch((ex) => {
          //TODO: log client errors
          //TODO: SHOW ERROR

          this.setState({
            isLoading: false,
            submiterrormessage: submitSystemError,
            submitStarted: false,
          });
        });

      this.setState({
        isLoading: true,
        successmessage: undefined,
      });
    }

  };

  public StartSelection = (committee: any, role: any) => {
    this.setState({
      submiterrormessage: undefined,
      selectionStarted: true,
      submitStarted: false,
      currentSignup: {
        committee: committee,
        role: role
      },
    });
  };


  public SubmitSelection = (currentSignup: Signup) => {
    this.setState({
      step: this.state.step,
      isLoading: this.state.isLoading,
      errormessage: this.state.errormessage,
      selectionStarted: true,
      submitStarted: true,
      currentSignup: currentSignup,
    });
    this.handleSubmit();
  };

  public componentDidMount() {
    this.ensureDataFetched();
  }
  private ensureDataFetched() {
    sanityClient
      .fetch(
        `*[_type == "post" && "Committee" in categories[]->title] | order(title){
    title,
    slug,
    body,
    mainImage{
      asset->{
      _slug,
      url
    }
  }
}`
      )
      .then((data: any) => {
        this.setState({
          postData: data,
        });
      })
      .catch(console.error);

  }

  public render() {

    const strokeStyle = {
      "--tan": 0.41,
      fontSize: 16
    } as React.CSSProperties;
    const { step } = this.state;
    let parent = this;
    return (
      <section id="committees" className="anchor">
        <div className={"alert-bar alert alert-success" + ((this.state.successmessage === undefined) ? " alert-bar-hidden" : "")} role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <strong>Success!</strong> {this.state.successmessage}
        </div>
        <div className="container bg-light">
          <h1 className="heading text-center showcase-item">Lehman Jackson Elementary Committee Interest Form</h1>
          <div className="content">
            <div className="container d-flex justify-content-center">
              <div className="d-flex flex-column justify-content-between">
                <section className="showcase-container">


                  <div className="showcase-item">
                    {this.renderMembership(step, parent)}
                  </div>
                  {(parent.state.step !== "ExistingMember" && parent.state.step !== "NewMember" && parent.state.step !== "SubmitSuccess") &&
                    <div
                      className={"hero-icon-flat yellow big-div"}
                    >
                      <span className="main fa-layers fa-fw">
                        <span
                          style={{ fontWeight: 900, fontSize: 90, color: "black" }}
                          className="fa-layers-text fa-inverse"
                          data-fa-transform="shrink-10 "
                        >
                          OR
                        </span>
                      </span>

                    </div>
                  }
                  {(parent.state.step !== "ExistingMember" && parent.state.step !== "NewMember" && parent.state.step !== "SubmitSuccess") &&
                    <div className="showcase-item">

                      <Card>
                        <CardBody>View the info below then fill out our <a target="_blank" role="button" className="btn btn-outline-primary font-weight-bold" href="https://bit.ly/3uJ33RB">GOOGLE FORM</a> </CardBody>
                      </Card>

                    </div>
                  }
                </section>
              </div>
            </div>
            {this.renderCommittees(parent)}

            {(parent.state.step !== "ExistingMember" && parent.state.step !== "NewMember" && parent.state.step !== "SubmitSuccess") &&
              <Card className="showcase-item">
                <CardBody>View the info above then fill out our  <a target="_blank" role="button" className="btn btn-outline-primary font-weight-bold" href="https://bit.ly/3uJ33RB">GOOGLE FORM</a> </CardBody>
              </Card>
            }
          </div>
        </div>

        <Modal isOpen={this.state.isLoading}>
          <ModalHeader>
            Please Wait...
          </ModalHeader>
          <ModalBody>
            <div className="spinner-grow text-warning" role="status">
              {/* <span className="visually-hidden">Loading...</span> */}
            </div>
            this might take a few moments
          </ModalBody>
        </Modal>
      </section>

    );


  }


  public renderMembership(step: string, parent: any) {

    let message = "Sign In to submit your interest to one or more committees:";

    switch (step) {
      case "ErrorLogin":
      case "Login":

        break;
      case "NewMember":
      case "ExistingMember":
      case "SubmitSuccess":
        message = "Thank you for signing in.  Please select one or more committees below to record your interest:"
        break;

        break;
      // never forget the default case, otherwise VS code would be mad!
      default:
      // do nothing
    }

    return (
      <GoogleSignIn
        Message={message}
        Errors={[parent.state.errormessage]}
        isLoggedIn={this.state.isLoggedIn}
        onSuccess={(res: any) => {
          console.log(res);
          this.setState({
            step: "Checking",
            isLoading: false,
            currentUser: emptyUser,
            errormessage: "",
          });
          this.Authorize(res);
        }}
        onFailure={() => {
          console.log("Failure");
          this.setState({
            step: "ErrorLogin",
            isLoading: false,
            currentUser: emptyUser,
            errormessage: systemError,
          });

        }}
        parentLogout={() => {
          this.setState({
            isOpen: false,
            step: "Login",
            isLoading: false,
            errormessage: undefined,
            submiterrormessage: undefined,
            successmessage: undefined,
            selectionStarted: false,
            submitStarted: false,
            currentUser: emptyUser,
            currentSignup: { committee: "", role: "" },
            errors: [],
            isLoggedIn: false,
          });
        }}
        UserImage={this.state.currentUser.thumbnail}

      />
    );


  }

  public renderCommittees(parent: any) {

    let posts: Post[] = parent.state.postData;

    return (
      <div>

        <section className="showcase-container">
          {posts.map((item, index) => {

            let itemInterest = "No Selection";
            let currentInterests = parent.state.currentUser && parent.state.currentUser.currentInterests;
            let thisItemInterest = currentInterests && (currentInterests.length > 0) ? currentInterests.find((e: Signup) => e.committee === item.title) : "No Selection";
            if (thisItemInterest && thisItemInterest.role !== undefined) {
              itemInterest = thisItemInterest.role;
            }

            return (

              <div key={index} className="showcase-item">
                <h2 >{item.title}</h2>
                {item.mainImage && <img
                  src={item.mainImage && item.mainImage.asset && item.mainImage.asset.url}
                  alt="Image"
                  className="img-fluid"
                />}

                <BlockContent
                  blocks={item.body}
                  projectId={sanityClient.config().projectId}
                  dataset={sanityClient.config().dataset}
                />
                <span className="card-muted mb-2"></span>
                {(parent.state.step === "ExistingMember"
                  || parent.state.step === "NewMember"
                  || parent.state.step === "SubmitSuccess") && <div className="item2">
                    <div className="border border-primary rounded font-weight-normal">Im interested in:

                      <CommitteeButton
                        OnClick={() => { if (itemInterest !== "Chairperson") parent.StartSelection(item.title, "Chairperson") }}
                        Title={item.title}
                        ItemInterest={itemInterest}
                        Role="Chairperson"
                      ></CommitteeButton>


                      <CommitteeButton
                        OnClick={() => { if (itemInterest !== "Co-Chair") parent.StartSelection(item.title, "Co-Chair") }}
                        Title={item.title}
                        ItemInterest={itemInterest}
                        Role="Co-Chair"
                      ></CommitteeButton>

                      <CommitteeButton
                        OnClick={() => { if (itemInterest !== "Volunteer") parent.StartSelection(item.title, "Volunteer") }}
                        Title={item.title}
                        ItemInterest={itemInterest}
                        Role="Volunteer"
                      ></CommitteeButton>

                      <button type="button" onClick={() => { if (itemInterest !== "No Selection") parent.StartSelection(item.title, "No Selection") }}
                        className={"btn btn-outline-success mt-3" + ((itemInterest === "No Selection") ? " active" : "")}>
                        <i className={"fa-solid" + ((itemInterest === "No Selection") ? " fa-xmark" : " fa-xmark fa-beat")} ></i><div>No Selection</div></button>
                    </div>
                  </div>}

                <div className="mt-5"></div>
              </div>
            );
          })}

          <Modal isOpen={parent.state.selectionStarted} className="modal-bar">
            <ModalHeader>
              Submit Selection
            </ModalHeader>
            <ModalBody>
              <div>
                {this.state.submiterrormessage && <div className="alert alert-danger" role="alert">{this.state.submiterrormessage}</div>}
                {parent.state.currentSignup.role === "No Selection" && <p>Would you like to remove your response for <strong>{parent.state.currentSignup.committee}</strong>?</p>}
                {parent.state.currentSignup.role !== "No Selection" && <p>Would you like to submit your interest as <strong>{parent.state.currentSignup.role}</strong> for <strong>{parent.state.currentSignup.committee}</strong>?</p>}
              </div>
              <div className="d-flex justify-content-between">
                <button type="button"
                  disabled={parent.state.submitStarted} onClick={() => { parent.SubmitSelection(parent.state.currentSignup) }}
                  className="btn btn-outline-success active mt-3">

                  <div className="d-flex justify-content-between"><i className="fa fa-check medium m-1"></i><div>SUBMIT</div></div></button>
                {parent.state.submitStarted && <div><div className="spinner-grow text-success" role="status">

                </div><span className="visually-hidden">Processing...</span></div>}
                <button type="button"
                  disabled={parent.state.submitStarted} onClick={() => { parent.CancelSelection() }}
                  className="btn btn-outline-danger active mt-3">

                  <div className="d-flex justify-content-between"><i className="fa fa-xmark medium m-1"></i><div>Cancel</div></div></button>

              </div>
            </ModalBody>
          </Modal>
        </section>
      </div>
    );
  }
}
