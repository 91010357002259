import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader, NavItem, Navbar } from "reactstrap";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectCommitteeFormsOpen, toggleCommitteeForms } from "./modalSlice";

const CommitteeForms = () => {


    const CommitteeFormsModalIsOpen = useAppSelector(selectCommitteeFormsOpen);

    const dispatch = useAppDispatch();

    return (
        <Modal
            isOpen={CommitteeFormsModalIsOpen}
            toggle={() => { dispatch(toggleCommitteeForms()) }}
            backdrop={"static"}
            keyboard={false}
            className="searchmodal"
        >
            <ModalHeader toggle={() => { dispatch(toggleCommitteeForms()) }}>
                School Information
            </ModalHeader>
            <ModalBody>
                <div className="card">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><a href="https://drive.google.com/open?id=1Qz9RIZkls9NZjQO78IjRthksLAGKmrZ5" target="_blank">Treasurer Form</a></li>

                    </ul>
                </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>

    );
};

export default CommitteeForms;



