import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import "./scss/main.scss";
import { NavMenu } from './features/navmenu/NavMenu';
import { RaceDonationWizard } from './features/race/RaceDonationWizard';
import { MembershipDonationWizard } from './features/race/MembershipDonationWizard';
import Footer from "./features/footer";
import Showcase from "./features/showcase";
import { Calendar } from "./features/calendar/Calendar";
import Mission from "./features/mission";
import Members from "./features/members";
import Fundraisers from "./features/fundraisers";
import Contact from "./features/contact";
import { Minutes } from "./features/minutes/Minutes";
import Privacy from "./features/privacy";
import Tos from "./features/tos";
import { BoardReport } from "./features/board/BoardReports";
import CommitteeSignup from "./features/committees/CommitteeSignup";
import MusicSurvey from "./features/music/MusicSurvey";
import Committees from "./features/committees";
import EmergencyServices from "./features/landingpages/EmergencyServices";
import ChessInterest from "./features/chess/ChessInterest";
import Events from "./features/events";
import { YearbookViewer } from "./features/privateContent";
import { ChessContent } from "./features/chess/ChessContent";

function App() {


    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <Routes>
                        <Route path="/" element={<NavMenu />} />
                        <Route path="/:any" element={<NavMenu />} />
                        <Route path="/:any/:any" element={<NavMenu />} />
                    </Routes>
                </header>
                <Routes>

                    <Route path='/' element={
                        <div>
                            <Showcase />
                            <Calendar />
                            <Mission />
                            <Members />
                            <Committees />
                            <Minutes />
                            <Fundraisers />
                            <Contact />
                        </div>
                    } />
                    <Route path="/race" element={<RaceDonationWizard channelBase={0} />} />
                    <Route path="/membership" element={<MembershipDonationWizard channelBase={0} />} />
                    <Route path="/board" element={<BoardReport />} />
                    <Route path='/committees' element={<CommitteeSignup />} />
                    <Route path='/yearbook' element={<YearbookViewer />} />
                    <Route path='/yearbook/print' element={<YearbookViewer />} />
                    <Route path='/music' element={<MusicSurvey />} />
                    <Route path='/EmergencyServices' element={<EmergencyServices />} />
                    <Route path='/chess' element={<ChessInterest />} />
                    <Route path='/chess/interest' element={<ChessInterest />} />
                    <Route path='/chess/materials' element={<ChessContent />} />
                    <Route path='/events' element={<Events />} />
                    {/* <Route path="/sandbox/race" element={<RaceDonationWizard channelBase={100}/>} />
                    <Route path="/sandbox/membership" element={<MembershipDonationWizard channelBase={100}/>} />
                    <Route path="/staging/membership" element={<MembershipDonationWizard channelBase={200}/>} />
                    <Route path="/staging/race" element={<RaceDonationWizard channelBase={200}/>} /> */}
                    {/* <Route path="/race/donate" element={<DonateWizard />} /> */}
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/tos" element={<Tos />} />
                </Routes>

                <footer className="App-footer">
                    <Routes>
                        <Route path='/boxtops' element={<Footer />} />
                        <Route path='/yearbook/print' element={<Footer />} />
                        <Route path='/Yearbook/Print' element={<Footer />} />
                        <Route path='/yearbook' element={<Footer />} />
                        <Route path='/Yearbook' element={<Footer />} />
                        <Route path='/' element={<Footer />} />
                    </Routes>
                </footer>

            </div>
        </Router>
    );
}

export default App;