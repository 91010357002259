import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardTitle, Col, Container, NavItem, Navbar, Row } from "reactstrap";

const Privacy = () => {
    return (
        <section className="showcase bg1 roomfornav">
            <div id="overlay"></div>
            <div
                className="d-flex flex-column align-items-center "
            >
                <div className="donateheader">
                    <Container>
                        <Row>
                            <Col><Card>

                                <CardBody>
                                    <CardTitle className="text-center"><strong>
                                        Privacy Policy</strong></CardTitle>
                                    <CardSubtitle>We do not share any user information on this site.  If you login with google to the membership section we will store your name and email address for membership and committee interest purposes.</CardSubtitle>
                                    <hr></hr>
                                    <CardSubtitle>If you login with google to view the yearbook we will check your email address for appropriate school district or to see if you are authorized.  We will not retain any information.</CardSubtitle>
                                    <hr></hr>
                                    <CardSubtitle>If you login with google to the music section, we will check your email address for appropriate school district or to see if you are authorized.  We will record your votes with your email address.</CardSubtitle>
                                    <hr></hr>


                                </CardBody>
                            </Card></Col>
                        </Row>


                    </Container>
                </div>
            </div>
        </section>
    );
};

export default Privacy;



