import React, { useCallback, useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
    DonorInfoSave,
    FirstNameChange,
    GradeChange,
    LastNameChange,
    SetOrderComplete,
    SetValidationError,
    UpdateVenmoOrderAsync,
    addFormFields,
    gradeDDToggle,
    initpaypalButton,
    removeFormFields,
    selectDonor,
    selectOrderCompleteMessage,
    selectPaypalButton,
    selectSystemError,
    selectValidationError,
    selectchildrenArray,
    selectgradeDDArray,
} from './raceSlice';
import { Alert, Button, Card, CardBody, CardFooter, CardImg, CardSubtitle, CardText, CardTitle, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { selectActiveTab, setActiveTab } from '../tabs/tabSlice';

import { Form, Field, RadioGroup, Radio } from '@availity/form';
import * as yup from 'yup';
import classNames from 'classnames';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { UserProfile } from '../Interfaces';
import LoadingModal from '../LoadingModal';
import { setrefreshReCaptcha, setcaptchatoken, selectCaptchaToken, selectrefreshReCaptcha } from '../captcha/captchaSlice';
import { EffectsCard } from '../../components/EffectsCard';
import { Link } from 'react-router-dom';
import { VenmoButton } from './venmoButton';
import MemberSendIn from '../Modals/MemberSendIn';
import { toggleMemberSendIn } from '../Modals/modalSlice';

export function MembershipDonationWizard(props: { channelBase: number; }) {
    const childrenArray = useAppSelector(selectchildrenArray);
    const gradeDDArray = useAppSelector(selectgradeDDArray);

    let paypalButton = useAppSelector(selectPaypalButton);
    const currentdonor = useAppSelector(selectDonor);
    const captchatoken = useAppSelector(selectCaptchaToken);
    const refreshReCaptcha = useAppSelector(selectrefreshReCaptcha);

    const ValidationError = useAppSelector(selectValidationError);

    const SystemError = useAppSelector(selectSystemError);
    const OrderCompleteMessage = useAppSelector(selectOrderCompleteMessage);


    const dispatch = useAppDispatch();

    const activeTab = useAppSelector(selectActiveTab);

    const grades = ["K", "1", "2", "3", "4", "5", "6"];

    const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/


    let childrenValidationScheme: any = {
    }
    let childrenInitialValues: any = {
    }

    for (let i = 0; i < childrenArray.length; i++) {
        childrenValidationScheme["FirstName" + i] = yup.string().required('Please enter a First Name');
        childrenValidationScheme["LastName" + i] = yup.string().required('Please enter a Last Name');

        childrenInitialValues["FirstName" + i] = childrenArray[i].firstname;
        childrenInitialValues["LastName" + i] = childrenArray[i].lastname;
    }

    const myStyle = {
        maxWidth: '500px'
    }
    return (
        <Container style={myStyle} className="roomfornav">
            <div>
                <h2>Please take part in our annual PTO membership drive!</h2>
                <h3>We invite parents, guardians, grandparents, and teachers to be active in the PTO programs at Lehman-Jackson Elementary. </h3>
                <h3>The cost for each member is $5.00 and will go directly to activities for the students at Lehman-Jackson. </h3>

                <h3><b>Please note that in order to be eligible to chaperone for the LJE field trips for the 2024-2025 school year, you need to join the PTO and submit payment by 11/1/2024.</b></h3>

            </div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: activeTab === 1 })}
                        onClick={() => {
                            dispatch(setActiveTab(1))
                        }} to={''}                  >
                        Step 1
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: activeTab === 2 })}
                        onClick={() => {
                            //  dispatch(setActiveTab(2)) 
                        }} to={''}                  >
                        Step 2
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: activeTab === 3 })}
                        onClick={() => {
                            // dispatch(setActiveTab(3))
                        }} to={''}                  >
                        Step 3
                    </NavLink>
                </NavItem>

            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    Lets get started with your contact information!
                    <div className="form-row">
                        <Form
                            initialValues={{
                                Name: '',
                                // phone: '',
                                // email: '',
                                clearances: '',

                            }}
                            validationSchema={
                                yup.object().shape({
                                    Name: yup.string().required('Please enter a Name'),
                                    // phone: yup.string().required('Please enter a Phone').matches(phoneRegExp, 'Phone number is not valid'),
                                    // email: yup.string().email("Invalid email format").required('Please enter a valid email'),
                                    clearances: yup.string().required('Please indicate if your clearances are on file in the school office'),
                                })}
                            onSubmit={(values) => {

                                let vals: UserProfile = JSON.parse(JSON.stringify(values));
                                vals.captchatoken = captchatoken;

                                dispatch(DonorInfoSave(vals));

                                dispatch(setActiveTab(2));
                                // dispatch(setrefreshReCaptcha(!refreshReCaptcha));
                            }}
                        >
                            {(SystemError !== '') && <Alert color="danger">There was a system error.  Please try again or if the issue persists please send email to <a href="mailto:lehmanjacksonptoboard@gmail.com">lehmanjacksonptoboard@gmail.com</a>. </Alert>}

                            <div className="col-md-6 mb-3">
                                <Field
                                    name="Name"
                                    label="Contact Name"
                                    type="text"
                                    maxLength={50}
                                    style={{ maxWidth: "370px" }}
                                />
                            </div>

                            {/* <div className="col-md-6 mb-3">
                                <Field
                                    name="phone"
                                    label="Contact Cell Phone"
                                    type="text"
                                    maxLength={15}
                                    style={{ maxWidth: "370px" }}
                                />

                            </div>

                            <div className="col-md-6 mb-3">
                                <Field
                                    name="email"
                                    label="Contact Email"
                                    type="text"
                                    maxLength={100}
                                    style={{ maxWidth: "370px" }}
                                />
                            </div> */}
                            <div className="col-md-6 mb-3">
                                <RadioGroup name="clearances" label="Clearances on file in school office">
                                    <Radio name="clearances" label="Yes" value="Yes" />
                                    <Radio name="clearances" label="No" value="No" />
                                </RadioGroup>
                            </div>
                            <div className="d-flex flex-row justify-content-between">

                                <div className="d-flex flex-col">
                                    <button className="btn btn-inverse btn-primary btn-sm" id="submit">
                                        <strong>Next</strong>
                                    </button>

                                </div>

                            </div>

                            {/* <LoadingModal isLoading={Status === "loading"}></LoadingModal> */}
                        </Form>
                    </div>
                </TabPane>
                <TabPane tabId={2}>
                    <div className="form-row">
                        <Form
                            initialValues={childrenInitialValues}
                            validationSchema={
                                yup.object().shape(childrenValidationScheme)
                            }
                            onSubmit={(values) => {

                                // let vals: UserProfile = JSON.parse(JSON.stringify(values));
                                // vals.captchatoken = captchatoken;

                                let isValid = true;

                                for (let index = 0; index < childrenArray.length; index++) {
                                    if (childrenArray[index].gradesection === undefined || childrenArray[index].gradesection === "") {
                                        isValid = false;
                                        dispatch(SetValidationError("Please select a grade and section for each student"));
                                    }
                                    if (childrenArray[index].firstname === undefined || childrenArray[index].firstname === "") {
                                        isValid = false;
                                        dispatch(SetValidationError("Please select a first and last name for each student"));
                                    }
                                    if (childrenArray[index].lastname === undefined || childrenArray[index].lastname === "") {
                                        isValid = false;
                                        dispatch(SetValidationError("Please select a first and last name for each student"));
                                    }
                                }

                                if (isValid) {
                                    dispatch(initpaypalButton({
                                        buttonNum: Math.floor(Math.random() * 101), channel: (props.channelBase === 0 || props.channelBase === 200) ? 2 : 102,
                                        consumerOnComplete: function (params: any) {
                                            // Your onComplete handler
                                            console.table(params);
                                            dispatch(SetOrderComplete("Thank you for your donation!  Your order number is " + params.tx + " please keep this number for your records."));
                                        }
                                    }))
                                    dispatch(setActiveTab(3));
                                }
                                // dispatch(setrefreshReCaptcha(!refreshReCaptcha));
                            }}
                        >
                            {(SystemError !== '') && <Alert color="danger">There was a system error.  Please try again or if the issue persists please send email to <a href="mailto:lehmanjacksonptoboard@gmail.com">lehmanjacksonptoboard@gmail.com</a>. </Alert>}


                            <Label for="childList">Student or students attending LJE</Label>
                            {(ValidationError !== '') && <div className='text-danger'>{ValidationError}</div>}

                            {childrenArray.map(
                                (element, index) => {

                                    let propsordefault = element.gradesection
                                        ? element.gradesection
                                        : "Select Grade-Section";
                                    return (

                                        <div key={"Child" + index}>
                                            <div className="col-md-6 mb-3">
                                                <Field
                                                    name={"FirstName" + index}
                                                    label="First Name"
                                                    type="text"
                                                    maxLength={50}
                                                    style={{ maxWidth: "370px" }}
                                                    key={"Childfirstname" + index}
                                                    placeholder="First Name"
                                                    value={element.firstname || ""}
                                                    required
                                                    onChange={(e) =>
                                                        dispatch(FirstNameChange({ i: index, firstname: e.target.value }))
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">

                                                <Field
                                                    name={"LastName" + index}
                                                    label="Last Name"
                                                    type="text"
                                                    maxLength={50}
                                                    style={{ maxWidth: "370px" }}
                                                    key={"Childlastname" + index}
                                                    placeholder="Last Name"
                                                    value={element.lastname || ""}
                                                    required
                                                    onChange={(e) =>
                                                        dispatch(LastNameChange({ i: index, lastname: e.target.value }))
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">

                                                <Dropdown key={"GradeDD" + index} isOpen={gradeDDArray[index]} toggle={() =>
                                                    dispatch(gradeDDToggle({ i: index }))
                                                }>
                                                    <DropdownToggle caret>
                                                        {propsordefault}
                                                    </DropdownToggle>
                                                    <DropdownMenu key={"GradeDDM" + index}>
                                                        {grades.map((grade) => (
                                                            <div className="row" key={grade}>
                                                                <div className="col-sm-4">
                                                                    <DropdownItem
                                                                        value={grade + "-1"}
                                                                        onClick={() => {
                                                                            dispatch(GradeChange({ i: index, gradesection: grade + "-1" }))
                                                                        }}
                                                                    >
                                                                        {grade + "-1"}
                                                                    </DropdownItem>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <DropdownItem onClick={() => {
                                                                        dispatch(GradeChange({ i: index, gradesection: grade + "-2" }))
                                                                    }}>
                                                                        {grade + "-2"}
                                                                    </DropdownItem>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <DropdownItem onClick={() => {
                                                                        dispatch(GradeChange({ i: index, gradesection: grade + "-3" }))
                                                                    }}>
                                                                        {grade + "-3"}
                                                                    </DropdownItem>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                            {index > 0 ||
                                                (index <= 0 &&
                                                    childrenArray.length >
                                                    1) ? (
                                                <button key={"Graderm" + index}
                                                    type="button"
                                                    className="button remove"
                                                    onClick={() =>
                                                        dispatch(removeFormFields({ i: index }))
                                                    }
                                                >
                                                                Remove Student
                                                </button>
                                            ) : null}
                                        </div>
                                    );
                                }
                            )}
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <Button onClick={() => dispatch(addFormFields())}>
                                        <span className="fa fa-plus"></span> Add Student
                                    </Button>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">

                                <div className="d-flex flex-col">
                                    <button className="btn btn-inverse btn-primary btn-sm" id="submitChildren" type='submit'>
                                        <strong>Next</strong>
                                    </button>

                                </div>

                            </div>


                            {/* <LoadingModal isLoading={Status === "loading"}></LoadingModal> */}
                        </Form>
                    </div>
                </TabPane>
                <TabPane tabId={3}>

                    <Card>
                        <CardTitle>Pay via paypal or send payment to school!</CardTitle>
                        <CardBody >
                            {OrderCompleteMessage === "" &&
                                <div id={(props.channelBase === 0 || props.channelBase === 200) ? "ppparent" + 2 : "ppparent" + 102}>
                                    <div id={(props.channelBase === 0 || props.channelBase === 200) ? "paypal-donate-button-container" + 2 : "paypal-donate-button-container" + 102}></div>
                                </div>
                            }

                            {/* {OrderCompleteMessage === "" && (props.channelBase === 100 || props.channelBase === 200) &&
                                <>
                                    <hr />
                                    <VenmoButton channelBase={props.channelBase} currentdonor={currentdonor} captchatoken={captchatoken}
                                        amount={5}
                                        DonationName={"Membership Drive"}
                                        onApprove={(data: any) => {

                                            return new Promise((resolveInner) => {
                                                dispatch(UpdateVenmoOrderAsync({ DonationName: "Membership Drive", amount: 5, sol: (props.channelBase === 0 || props.channelBase === 200) ? "l" : "Sandbox", orderid: data.orderID }));
                                            });
                                        }}
                                        createOrder={() => {

                                            let dataIn: UserProfile = JSON.parse(JSON.stringify({ captchatoken: captchatoken }));
                                            let postData = { DonationName: "Membership Drive", amount: 5, sol: (props.channelBase === 0 || props.channelBase === 200) ? "l" : "Sandbox", CustomData: { Name: currentdonor.Name, email: currentdonor.email, cell: currentdonor.phone, childrenArray: currentdonor.childrenArray, Clearances: currentdonor.clearances }, userProfile: dataIn }

                                            return fetch(
                                                process.env.REACT_APP_API_HOST +
                                                `/Transactions/CreateVenmoOrder`,
                                                {
                                                    method: "POST",
                                                    // credentials: "include",
                                                    body: JSON.stringify(postData),
                                                    headers: {
                                                        pragma: "no-cache",
                                                        "cache-control": "no-cache",
                                                        "content-type": "application/json",
                                                    },
                                                }
                                            )
                                                .then((response: Response) => {
                                                    if (!response.ok) {
                                                        if (response.statusText && response.statusText !== '') {
                                                            throw response.statusText;
                                                        }
                                                        else {
                                                            throw "System Error";
                                                        }
                                                    } else {
                                                        return response.json() as Promise<string>;
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.table(error);
                                                    throw error;
                                                });

                                        }}
                                    ></VenmoButton>
                                </>

                            } */}

                            {OrderCompleteMessage !== "" && <Alert>
                                {OrderCompleteMessage}
                            </Alert>

                            }
                        </CardBody>
                        {OrderCompleteMessage === "" &&
                        <CardFooter>
                            <h3> *if you wish to send in the $5.00/person payment via check or cash, send payment with your contact info and students info to school clearly label it as "membership drive" in a sealed envelope. Checks can be made payable to "LJE-PTO".</h3>
                            <Button className='btn btn-info-outline' color="info"
                                onClick={() => {
                                    dispatch(toggleMemberSendIn());
                                        dispatch(setrefreshReCaptcha(!refreshReCaptcha));
                                }}>
                                    Cash/Check Register here!
                            </Button>
                            <MemberSendIn captchatoken={captchatoken}></MemberSendIn>
                        </CardFooter>
                        }
                    </Card>
                </TabPane>
            </TabContent>
            <div className="d-flex flex-col">
                <div className="d-flex flex-col">
                    <GoogleReCaptcha
                        refreshReCaptcha={refreshReCaptcha}
                        onVerify={useCallback((token) => {
                            dispatch(setcaptchatoken(token))
                        }, [refreshReCaptcha])}
                    />
                    {/* <div id="recaptchaform"></div> */}
                </div>
            </div>
        </Container>
    );
}
