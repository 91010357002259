import 'bootstrap/dist/css/bootstrap.css';
import './scss/main.css';
import 'bootstrap/dist/js/bootstrap.js';
// import './scss/onehero.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            {/* <App /> */}
            <GoogleReCaptchaProvider
            
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITEKEY ? process.env.REACT_APP_CAPTCHA_SITEKEY : ""}
                container={{ // optional to render inside custom element
                    // element: "recaptchaform",
                    parameters: {
                        badge: 'bottomright', // optional, default undefined
                        theme: 'dark', // optional, default undefined
                    }
                }}
            >
                <App />
                
            </GoogleReCaptchaProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
