import React from "react";
import { Link } from "react-router-dom";
import { NavItem, Navbar } from "reactstrap";

const Mission = () => {
    return (
        <section id="mission" className="anchor">
        <div className="container bg-light">
            <div className="container mb-5"></div>
                
                    <h2 className="heading text-center">Mission</h2> 
                <div className="row">
                    <div className="d-none d-sm-inline col-sm-4">
                            <img src="https://cdn.sanity.io/images/vp13tjdy/production/d4df8126c22bf4cdf2181b40dc1b869ccd311cc9-2435x2140.jpg" alt="" className="w-75 img-fluid"/>                    
                    </div>
                    <div className="col-12 col-sm-8 bg-light">
                            <p className="lead">Lehman-Jackson Elementary PTO is organized for the purpose of supporting the education of 
                                    children at Lehman-Jackson Elementary by fostering relationships among the school, parents, and teachers.  
                                    The PTO develops closer connections between school and home by encouraging parent involvement, enhances the 
                                    educational experience by supporting academic and enrichment activities and improves the environment at our school by providing 
                                    volunteer and financial support.</p>
                    </div>
                </div>
            </div>
    </section>
    );
};

export default Mission;



