import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface CaptchaState {
  captchatoken: string;
  refreshReCaptcha: boolean;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CaptchaState = {
  captchatoken: '',
  refreshReCaptcha: false,
  status: 'idle',
};


export const captchaSlice = createSlice({
  name: 'captcha',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {   
    // Use the PayloadAction type to declare the contents of `action.payload`
    setcaptchatoken: (state, action: PayloadAction<string>) => {
      state.captchatoken = action.payload;
    },
    setrefreshReCaptcha: (state, action: PayloadAction<boolean>) => {
      state.refreshReCaptcha = action.payload;
    },
  },
  
});

export const { setcaptchatoken } = captchaSlice.actions;
export const { setrefreshReCaptcha } = captchaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.captcha.value)`
export const selectCaptchaToken = (state: RootState) => state.captcha.captchatoken;
export const selectrefreshReCaptcha = (state: RootState) => state.captcha.refreshReCaptcha;

export default captchaSlice.reducer;
