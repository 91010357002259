import { handleValidLoginAsync, selectUserData, handleLoginError, handleLogout } from "../user/userSlice";
import { GoogleSignIn } from "../user/GoogleSignIn";

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, CardSubtitle, CardText, CardLink, Container } from "reactstrap";
import { MediaItem } from "../user/UserInterfaces";

const message = "Sign In to view lje-pto board reports:";
const systemError = "We could not authorize you.  This page is for LJE-PTO board members or specific authorized users.";
export function BoardReport() {

    const dispatch = useAppDispatch();
    const userData = useAppSelector(selectUserData);

    return (
        <Container className="roomfornav">
            <GoogleSignIn
                Message={message}
                Errors={userData.errors}
                isLoggedIn={userData.isLoggedIn}
                //spg - removed type CredentialResponse here, used any.. since it wouldnt resolve.. todo: fix this
                onSuccess={(res: any) => {
                    console.log(res);

                    dispatch(handleValidLoginAsync({ res: res }));
                }}
                onFailure={() => {
                    dispatch(handleLoginError({ errormessage: systemError }));
                }}
                parentLogout={() => {

                    dispatch(handleLogout());

                }}

                UserImage={userData.currentUser.thumbnail}
            />
            {userData.isLoggedIn && userData.mediaItems && userData.mediaItems.map((item: MediaItem) => {
                return (
                    <Card
                        style={{
                            width: '18rem'
                        }}
                    >
                        <CardBody>
                            <CardTitle tag="h5">
                               {item.filename}
                            </CardTitle>

                        </CardBody>

                        <CardBody>
                            <CardLink href={item.baseUrl} target="_blank">
                                Open in Google Sheets
                            </CardLink>
                        </CardBody>
                    </Card>

                );
            })
            }
        </Container>
    );

}