import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, NavItem, Navbar } from "reactstrap";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectMemberSendInOpen, toggleMemberSendIn } from "./modalSlice";
import { DonorInfoSave, SubmitIOweYouAsync, selectDonor, selectOrderCompleteMessage, selectSystemError } from "../race/raceSlice";

import { Form, Field, RadioGroup, Radio } from '@availity/form';
import * as yup from 'yup';
import { selectCaptchaToken, selectrefreshReCaptcha, setrefreshReCaptcha } from "../captcha/captchaSlice";
import { UserProfile } from "../Interfaces";

const MemberSendIn = (props: { captchatoken: string }) => {


    const SystemError = useAppSelector(selectSystemError);
    const MemberSendInModalIsOpen = useAppSelector(selectMemberSendInOpen);
    const captchatoken = useAppSelector(selectCaptchaToken);
    const refreshReCaptcha = useAppSelector(selectrefreshReCaptcha);
    const currentdonor = useAppSelector(selectDonor);
    const OrderCompleteMessage = useAppSelector(selectOrderCompleteMessage);

    const dispatch = useAppDispatch();

    return (
        <Modal
            isOpen={MemberSendInModalIsOpen}
            toggle={() => { dispatch(toggleMemberSendIn()) }}
            backdrop={"static"}
            keyboard={false}
            className="searchmodal"
        >
            <ModalHeader toggle={() => { dispatch(toggleMemberSendIn()) }}>
                Submit contact here and send in membership dues

            </ModalHeader>
            <ModalBody>

                {OrderCompleteMessage !== "" && <Alert>
                    {OrderCompleteMessage}
                </Alert>

                }

                {OrderCompleteMessage === "" &&
                    <>
                        <h4> *if you wish to send in the $5.00/person payment via check or cash, send payment with your contact info and students info to school clearly label it as "membership drive" in a sealed envelope. Checks can be made payable to "LJE-PTO".</h4>
                        <Form
                            initialValues={{
                                Name: currentdonor.Name,
                                phone: currentdonor.phone,
                                email: currentdonor.email,
                                clearances: currentdonor.clearances,

                            }}
                            validationSchema={
                                yup.object().shape({
                                    Name: yup.string().required('Please enter a Name'),
                                    // phone: yup.string().required('Please enter a Phone').matches(phoneRegExp, 'Phone number is not valid'),
                                    email: yup.string().email("Invalid email format").required('Please enter a valid email'),
                                    clearances: yup.string().required('Please indicate if your clearances are on file in the school office'),
                                })}
                            onSubmit={(values) => {

                                let vals: UserProfile = JSON.parse(JSON.stringify(values));
                                vals.captchatoken = captchatoken;

                                dispatch(DonorInfoSave(vals));
                                dispatch(setrefreshReCaptcha(!refreshReCaptcha));
                                dispatch(SubmitIOweYouAsync({ captchatoken: props.captchatoken }));
                            }}
                        >
                            {(SystemError !== '') && <Alert color="danger">There was a system error.  Please try again or if the issue persists please send email to <a href="mailto:lehmanjacksonptoboard@gmail.com">lehmanjacksonptoboard@gmail.com</a>. </Alert>}

                            <div className="col-md-6 mb-3">
                                <Field
                                    name="Name"
                                    label="Contact Name"
                                    type="text"
                                    maxLength={50}
                                    style={{ maxWidth: "370px" }}
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <Field
                                    name="phone"
                                    label="Contact Cell Phone"
                                    type="text"
                                    maxLength={15}
                                    style={{ maxWidth: "370px" }}
                                />

                            </div>

                            <div className="col-md-6 mb-3">
                                <Field
                                    name="email"
                                    label="Contact Email"
                                    type="text"
                                    maxLength={100}
                                    style={{ maxWidth: "370px" }}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <RadioGroup name="clearances" label="Clearances on file in school office">
                                    <Radio name="clearances" label="Yes" value="Yes" />
                                    <Radio name="clearances" label="No" value="No" />
                                </RadioGroup>
                            </div>
                            <div className="d-flex flex-row justify-content-between">

                                <div className="d-flex flex-col">
                                    <button className="btn btn-inverse btn-primary btn-sm" id="submit">
                                        <strong>Submit</strong>
                                    </button>

                                </div>

                            </div>

                            {/* <LoadingModal isLoading={Status === "loading"}></LoadingModal> */}
                        </Form>
                    </>
                }
            </ModalBody>
            <ModalFooter>
                {/* <h3> *I will send in the payment as noted above.</h3> */}
            </ModalFooter>
        </Modal>

    );
};

export default MemberSendIn;



