import { useCallback, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  fetchminutesAsync,
  selectminutes,
} from './minutesSlice';


import 'moment-timezone';
import { toggleSchoolLinks } from '../Modals/modalSlice';


export function Minutes() {
  const minutes = useAppSelector(selectminutes);
  const dispatch = useAppDispatch();

  const initFetch = useCallback(() => {
    if (minutes && minutes.length > 0) { } else {
      dispatch(fetchminutesAsync());

    }
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])
  return (
    <section id="minutes" className="anchor">
      <div className="container text-center mt-5">
        <h2 className="heading text-center">Minutes / Info</h2>
        <div className="row text-center">
          <div className="col-md-4 list-group">
            {
              minutes.map((minute, index) =>
                <div key={'mykey' + index} >
                  <a target="_blank" rel="noopener noreferrer" className="btn btn-info"
                    href={minute.url}>{minute.name}</a>

                </div>
              )}

          </div>
          <div className="col-md-4 list-group">
          </div>
          <div className="col-md-4 list-group">

            <button className="mx-5 mb-5 btn btn-lg btn-secondary" onClick={() => {
              dispatch(toggleSchoolLinks());
            }}>School Info</button>
          </div>
        </div>
      </div>
    </section>
  );
}