import * as React from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
// import { color } from "d3";

interface CommitteeButtonProps {
  Title: string;
  ItemInterest: string;
  Role: string;
  Text?: string;
  OnClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const CommitteeButton: React.FC<CommitteeButtonProps> = ({
  Title,
  ItemInterest,
  Role,
  Text,
  OnClick,
}) => {
  let iconClass = (ItemInterest === Role) ? "fa-solid fa-check" : "fa-solid fa-circle-plus fa-beat";
  const history = useNavigate();
  const routeChange = (path: string | undefined) => {
    if (path) history(path);
  };
  return (
    <button key={'btn' + Title + Role + ItemInterest} type="button" onClick={OnClick}
      className={"btn btn-outline-success" + ((ItemInterest === Role) ? " active" : "")}>
      <i className={iconClass}></i>
      <div>{Text || Role}</div></button>
  );
};
