import * as React from "react";
import { Badge, Card, CardBody, CardHeader } from "reactstrap";

interface GradeSelectorProps {
  selectedGrade: string;
  setGrade: Function;
}

export const GradeSelector: React.FC<GradeSelectorProps> = ({
  selectedGrade,
  setGrade,
}) => {

  // let grades = ['K', '1', '2', '3', '4', '5', '6']
  let grades = ['5th/6th']
  let rand = Math.floor(Math.random() * Math.floor(1000));
  return (

    <Card
      className="my-2"
      color="dark"
      outline
    >
      <CardHeader className="pt-1 pb-1">
        Select GRADE
      </CardHeader>
      <CardBody className="p-1 p-1">

        <div className="musicselected-row">
          {grades.map((item: string, index) => {

            return (
              <div key={index+ rand} className="musicselected-row">
                <div
                  className="musicselected-item-inner">
                  <button key={'btn' + item} type="button" onClick={() => { setGrade(item) }}
                    className={"btn btn-outline-success" + ((selectedGrade === item) ? " active" : "")}>
                    
                    <div>{item}</div></button>
                </div>
              </div>

            );
          })}
        </div>

      </CardBody>
    </Card>
  );
};
