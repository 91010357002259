import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface modalState {
  schoolLinksOpen: boolean;
  RaceInfoOpen: boolean;
  LeaderboardOpen: boolean;
  MemberSendInOpen: boolean;
  committeeFormsOpen: boolean;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: modalState = {
  schoolLinksOpen: false,
  RaceInfoOpen: false,
  LeaderboardOpen: false,
  MemberSendInOpen: false,
  committeeFormsOpen: false,
  status: 'idle',
};


export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {   
    // Use the PayloadAction type to declare the contents of `action.payload`
    toggleSchoolLinks: (state, action: PayloadAction) => {
      
      state.schoolLinksOpen = !state.schoolLinksOpen;
    },
    toggleCommitteeForms: (state, action: PayloadAction) => {
      
      state.committeeFormsOpen = !state.committeeFormsOpen;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    toggleRaceInfo: (state, action: PayloadAction) => {
      
      state.RaceInfoOpen = !state.RaceInfoOpen;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    toggleLeaderboard: (state, action: PayloadAction) => {
      
      state.LeaderboardOpen = !state.LeaderboardOpen;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    toggleMemberSendIn: (state, action: PayloadAction) => {
      
      state.MemberSendInOpen = !state.MemberSendInOpen;
    },
  }
},
  
);

export const { toggleLeaderboard } = modalSlice.actions;
export const { toggleRaceInfo } = modalSlice.actions;
export const { toggleSchoolLinks } = modalSlice.actions;
export const { toggleCommitteeForms } = modalSlice.actions;
export const { toggleMemberSendIn } = modalSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.btnDropright.value)`
export const selectSchoolLinksOpen = (state: RootState) => state.modal.schoolLinksOpen;
export const selectRaceInfoOpen = (state: RootState) => state.modal.RaceInfoOpen;
export const selectLeaderboardOpen = (state: RootState) => state.modal.LeaderboardOpen;
export const selectMemberSendInOpen = (state: RootState) => state.modal.MemberSendInOpen;
export const selectCommitteeFormsOpen = (state: RootState) => state.modal.committeeFormsOpen;

export default modalSlice.reducer;
