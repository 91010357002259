import { DonorCandidate, RaceFundraisingResults, UserProfile } from "./Interfaces";

// A mock function to mimic making an async request for data
export function fetchRace(sol = "l") {
  return fetch(
    process.env.REACT_APP_API_HOST +
    `/Transactions/Race?sol=${sol}`,
    {
      method: "GET",
      // credentials: "include",
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
        // 'Authorization': 'Bearer ' + localStorage.getItem("accessToken"),
        'Content-Type': 'application/x-www-form-urlencoded'
      },

    }
  )
    .then((response) => {
      if (!response.ok) {

        return {};

      } else {
        return response.json() as Promise<RaceFundraisingResults>;

      }
    })
    .catch((error) => {
      console.table(error);
      return error.then(Promise.reject.bind(Promise));
    });
}

export function UpdateVenmoOrder(DonationName: string, amount: number, sol: string, orderid: string, currentdonor: DonorCandidate) {

  // if (captchatoken) {
  let postData = { DonationName: DonationName, amount: amount, sol: sol, CustomData: { Name: currentdonor.Name, email: currentdonor.email, cell: currentdonor.phone, childrenArray: currentdonor.childrenArray, Clearances: currentdonor.clearances }, id: orderid }

  return fetch(
    process.env.REACT_APP_API_HOST +
    `/Transactions/UpdateVenmoOrder`,
    {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(postData),
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
        "content-type": "application/json",
      },
    }
  )
    .then((response: Response) => {
      if (!response.ok) {
        if (response.statusText && response.statusText !== '') {
          throw response.statusText;
        }
        else {
          throw new Error("System Error");
        }
      } else {
        return response.json() as Promise<string>;
      }
    })
    .catch((error) => {
      console.table(error);
      throw error;
    });

  // } else {
  //   throw new Error("Invalid Captcha");
  // }
}


export function SubmitIOweYou(currentdonor: DonorCandidate, captchatoken: string) {

  // if (captchatoken) {
  let postData = { donor: currentdonor  }

  return fetch(
    process.env.REACT_APP_API_HOST +
    `/Membership/SubmitIOweYou` + (captchatoken ? `?captchatoken=${captchatoken}` : ''),
    {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(postData),
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
        "content-type": "application/json",
      },
    }
  )
    .then((response: Response) => {
      if (!response.ok) {
        if (response.statusText && response.statusText !== '') {
          throw response.statusText;
        }
        else {
          throw new Error("System Error");
        }
      } else {
        return response.json() as Promise<string>;
      }
    })
    .catch((error) => {
      console.table(error);
      throw error;
    });

  // } else {
  //   throw new Error("Invalid Captcha");
  // }
}


// export function CreateVenmoOrder(dataIn: DonorCandidate, captchatoken: string) {

//   if (captchatoken) {

//     return fetch(
//       process.env.REACT_APP_API_HOST +
//       `/Transactions/CreateVenmoOrder`,
//       {
//         method: "POST",
//         // credentials: "include",
//         body: JSON.stringify(dataIn),
//         headers: {
//           pragma: "no-cache",
//           "cache-control": "no-cache",
//           "content-type": "application/json",
//         },
//       }
//     )
//       .then((response: Response) => {
//         if (!response.ok) {
//           if (response.statusText && response.statusText !== '') {
//             throw response.statusText;
//           }
//           else {
//             throw new Error("System Error");
//           }
//         }else
//         {
//           return  response.json() as Promise<string>;
//         }
//       })      
//       .catch((error) => {
//         console.table(error);
//         throw error;
//       });

//   } else {
//     throw new Error("Invalid Captcha");
//   }
// }


