import * as React from "react";
import { Badge, Card, CardBody, CardHeader } from "reactstrap";
import { Song } from "../features/music/Interfaces";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

interface SearchResultsProps {
  searchSongs: Song[];
  submitSpotifyID: Function;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  searchSongs,
  submitSpotifyID,
}) => {

  const tableRef = React.useRef()

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    // Update the document title using the browser API
    const table = $("#table").DataTable(
      {
        responsive: true,
        dom: 'Brtip',
        //@ts-ignore
        buttons: [
        ]
      });
    // Extra step to do extra clean-up.
    return function () {
      console.log("Table destroyed")
      table.destroy()
    }

  }, [])

  return (

    <table
      id="table"
      className="table m-0 search-table"
    >
      <thead>
        <tr>
          <th className="sticky-top text-nowrap">Artist</th>
          <th className="sticky-top text-nowrap">Song</th>
          <th className="sticky-top text-nowrap"></th>
        </tr>
      </thead>

      <tbody>
        {
          searchSongs.length > 0 && searchSongs.map((value, index) => {
            return (
              <tr key={"dt1" + index}>
                <td className="text-wrap">
                  {value.artist}
                </td>
                <td className="text-wrap">{value.song}</td>
                <td><button key={'btn' + value.spotifyid} type="button" onClick={() => {
                  submitSpotifyID(value.song, value.artist, value.spotifyid, "VOTE");
                }}
                  className={"btn btn-outline-success"}>

                  <div>ADD SONG
                  </div>
                </button>
                </td>
              </tr>
            );
          })
        }

      </tbody>
    </table>

  );
};
